import { t } from "ttag";

import { ChartSettingTableColumns } from "metabase/visualizations/components/settings/ChartSettingTableColumns";
import {
  columnSettings,
  getTitleForColumn,
} from "metabase/visualizations/lib/settings/column";
import { TREEMAP_SETTINGS } from "metabase/visualizations/lib/settings/treemapSettings";
import {
  getColumnSettingKey,
  findColumnIndexesForColumnSettings,
  findColumnSettingIndexesForColumns,
} from "metabase-lib/queries/utils/dataset";
import { getColumnKey } from "metabase-lib/queries/utils/get-column-key";
import type {
  DatasetColumn,
  DatasetData,
  Series,
  TableColumnOrderSetting,
  VisualizationSettings,
} from "metabase-types/api";

export const settings = {
  ...columnSettings({ hidden: true }),
  "treemap.chart_columns": {
    section: t`Columns`,
    widget: ChartSettingTableColumns,
    getValue: (
      [{ data }]: [{ data: DatasetData }],
      vizSettings: VisualizationSettings,
    ) => {
      const { cols } = data;

      function isValid(columnSettings: TableColumnOrderSetting[]) {
        const columnIndexes = findColumnIndexesForColumnSettings(
          cols,
          columnSettings.filter(({ enabled }) => enabled),
        );
        return columnIndexes.every(columnIndex => columnIndex >= 0);
      }

      function getValue(columnSettings: TableColumnOrderSetting[]) {
        const settingIndexes = findColumnSettingIndexesForColumns(
          cols,
          columnSettings,
        );

        return [
          ...columnSettings.map(setting => ({
            ...setting,
            key: getColumnSettingKey(setting),
          })),
          ...cols
            .filter((_, columnIndex) => settingIndexes[columnIndex] < 0)
            .map(column => ({
              name: column.name,
              key: getColumnKey(column),
              fieldRef: column.field_ref,
            })),
        ];
      }

      const columnSettings = vizSettings["treemap.chart_columns"];
      if (!columnSettings || !isValid(columnSettings)) {
        return getValue([]);
      } else {
        return getValue(columnSettings);
      }
    },
    getProps: (series: Series, settings: VisualizationSettings) => {
      const [
        {
          data: { cols },
        },
      ] = series;

      return {
        isTreeMap: true,
        columns: cols,
        getColumnName: (column: DatasetColumn) =>
          getTitleForColumn(column, series, settings),
      };
    },
  },
  ...TREEMAP_SETTINGS,
};
