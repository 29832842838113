var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var NI;$CLJS.LI=function(a){return $CLJS.n($CLJS.qB($CLJS.rd,$CLJS.qd,$CLJS.Xk)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.MI=function(a,b){return $CLJS.Me($CLJS.zd,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.ie(null,function(K,S,X,T){return function(){for(var da=C;;){var oa=$CLJS.y(da);if(oa){var Ma=oa,sb=$CLJS.z(Ma);if(oa=$CLJS.y(function(Qa,Xa,Ja,Ua,Ta,mb,dc,Kd){return function Rh(gd){return new $CLJS.ie(null,function(qz,Il,Un,zr,S_,Mv){return function(){for(;;){var Nv=
$CLJS.y(gd);if(Nv){if($CLJS.vd(Nv)){var Ov=$CLJS.ic(Nv),rz=$CLJS.D(Ov),Ek=$CLJS.le(rz);a:for(var Ar=0;;)if(Ar<rz){var ht=$CLJS.hd(Ov,Ar);ht=$CLJS.E.g(Mv,$CLJS.XD)||$CLJS.E.g(Un,$CLJS.XD)||$CLJS.yE(Mv,ht)&&$CLJS.yE(Un,ht);Ek.add(ht);Ar+=1}else{Ov=!0;break a}return Ov?$CLJS.oe($CLJS.qe(Ek),Rh($CLJS.jc(Nv))):$CLJS.oe($CLJS.qe(Ek),null)}Ek=$CLJS.z(Nv);return $CLJS.ae($CLJS.E.g(Mv,$CLJS.XD)||$CLJS.E.g(Un,$CLJS.XD)||$CLJS.yE(Mv,Ek)&&$CLJS.yE(Un,Ek),Rh($CLJS.Hc(Nv)))}return null}}}(Qa,Xa,Ja,Ua,Ta,mb,dc,
Kd),null,null)}}(da,K,sb,Ma,oa,S,X,T)($CLJS.iE)))return $CLJS.bf.g(oa,G($CLJS.Hc(da)));da=$CLJS.Hc(da)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.LI($CLJS.KD(b)))))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.LI($CLJS.KD(a)))}())};
NI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.Ne.j($CLJS.uE,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wi,new $CLJS.h(null,1,[$CLJS.ts,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);f=$CLJS.MD;
return $CLJS.n(f)?f:$CLJS.Le($CLJS.hl,$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.MI($CLJS.J.g(k,m),$CLJS.J.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Hc(d);return c(e,d)};b.l=c;return b}()};$CLJS.OI=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.PI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var QI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kr,$CLJS.dr],null)),RI=null,SI=0,TI=0;;)if(TI<SI){var Kia=RI.X(null,TI);$CLJS.xE(Kia,$CLJS.H([$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.oi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)],null)],null)],null)]));TI+=1}else{var UI=$CLJS.y(QI);if(UI){var VI=UI;if($CLJS.vd(VI)){var WI=$CLJS.ic(VI),Lia=$CLJS.jc(VI),
Mia=WI,Nia=$CLJS.D(WI);QI=Lia;RI=Mia;SI=Nia}else{var Oia=$CLJS.z(VI);$CLJS.xE(Oia,$CLJS.H([$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.oi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)],null)],null)],null)]));QI=$CLJS.B(VI);RI=null;SI=0}TI=0}else break}$CLJS.vE($CLJS.fr,$CLJS.H([$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null)]));
for(var XI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.zF],null)),YI=null,ZI=0,$I=0;;)if($I<ZI){var Pia=YI.X(null,$I);$CLJS.xE(Pia,$CLJS.H([$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.oi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)],null)],null)],null)]));$I+=1}else{var aJ=$CLJS.y(XI);if(aJ){var bJ=aJ;if($CLJS.vd(bJ)){var cJ=$CLJS.ic(bJ),Qia=$CLJS.jc(bJ),
Ria=cJ,Sia=$CLJS.D(cJ);XI=Qia;YI=Ria;ZI=Sia}else{var Tia=$CLJS.z(bJ);$CLJS.xE(Tia,$CLJS.H([$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.oi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$D],null)],null)],null)],null)]));XI=$CLJS.B(bJ);YI=null;ZI=0}$I=0}else break}
for(var dJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dq,$CLJS.Fq,$CLJS.zq,$CLJS.Bq],null)),eJ=null,fJ=0,gJ=0;;)if(gJ<fJ){var Uia=eJ.X(null,gJ);$CLJS.tE.l(NI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Uia,$CLJS.H([$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null)]));gJ+=1}else{var hJ=$CLJS.y(dJ);if(hJ){var iJ=hJ;if($CLJS.vd(iJ)){var jJ=$CLJS.ic(iJ),Via=$CLJS.jc(iJ),Wia=jJ,Xia=$CLJS.D(jJ);dJ=Via;eJ=Wia;fJ=Xia}else{var Yia=
$CLJS.z(iJ);$CLJS.tE.l(NI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Yia,$CLJS.H([$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null)]));dJ=$CLJS.B(iJ);eJ=null;fJ=0}gJ=0}else break}
$CLJS.tE.l(NI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.KE,$CLJS.H([$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null)]));
$CLJS.tE.l(NI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.QE,$CLJS.H([$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null)]));
for(var kJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JE,$CLJS.VE],null)),lJ=null,mJ=0,nJ=0;;)if(nJ<mJ){var Zia=lJ.X(null,nJ);$CLJS.vE(Zia,$CLJS.H([$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)]));nJ+=1}else{var oJ=$CLJS.y(kJ);if(oJ){var pJ=oJ;if($CLJS.vd(pJ)){var qJ=$CLJS.ic(pJ),$ia=$CLJS.jc(pJ),aja=qJ,bja=$CLJS.D(qJ);kJ=$ia;lJ=aja;mJ=bja}else{var cja=$CLJS.z(pJ);$CLJS.vE(cja,$CLJS.H([$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)]));kJ=$CLJS.B(pJ);
lJ=null;mJ=0}nJ=0}else break}
for(var rJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LE,$CLJS.SE],null)),sJ=null,tJ=0,uJ=0;;)if(uJ<tJ){var dja=sJ.X(null,uJ);$CLJS.vE(dja,$CLJS.H([$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)]));uJ+=1}else{var vJ=$CLJS.y(rJ);if(vJ){var wJ=vJ;if($CLJS.vd(wJ)){var xJ=$CLJS.ic(wJ),eja=$CLJS.jc(wJ),fja=xJ,gja=$CLJS.D(xJ);rJ=eja;sJ=fja;tJ=gja}else{var hja=$CLJS.z(wJ);$CLJS.vE(hja,$CLJS.H([$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)]));rJ=$CLJS.B(wJ);
sJ=null;tJ=0}uJ=0}else break}
for(var yJ=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null),zJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.$E,$CLJS.tF,$CLJS.hF,$CLJS.aF],null)),AJ=null,BJ=0,CJ=0;;)if(CJ<BJ){var DJ=AJ.X(null,CJ);$CLJS.IF.v(DJ,$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,DJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.uD,yJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.eE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null)],null));CJ+=1}else{var EJ=$CLJS.y(zJ);if(EJ){var FJ=EJ;if($CLJS.vd(FJ)){var GJ=$CLJS.ic(FJ),ija=$CLJS.jc(FJ),jja=GJ,kja=$CLJS.D(GJ);zJ=ija;AJ=jja;BJ=kja}else{var HJ=$CLJS.z(FJ);$CLJS.IF.v(HJ,$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,HJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.uD,yJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eE],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.eE],null)],null));zJ=$CLJS.B(FJ);AJ=null;BJ=0}CJ=0}else break}
$CLJS.IF.v($CLJS.dB,$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.dB],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.uD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gk,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.Qu,$CLJS.Vw,$CLJS.PE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.hE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$F],null)],null));$CLJS.IF.v($CLJS.GE,$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.GE],null),$CLJS.uD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.hD,$CLJS.pD],null)],null));
$CLJS.Y($CLJS.wF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.OI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.ar,$CLJS.Gq,$CLJS.zF,$CLJS.QE,$CLJS.KE,$CLJS.Dq,$CLJS.zq,$CLJS.Fq,$CLJS.Bq,$CLJS.JE,$CLJS.VE,$CLJS.LE,$CLJS.SE,$CLJS.hF,$CLJS.aF,$CLJS.$E,$CLJS.tF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PI,$CLJS.Bi],null)],null));