import type { TreemapSeriesType } from "@toast-ui/chart/types/options";

import type { RemappingHydratedChartData } from "metabase/visualizations/types";
import type { TableColumnOrderSetting } from "metabase-types/api";

export const buildChartDataWithColumnsSettings = (
  data: RemappingHydratedChartData,
  settings: TableColumnOrderSetting[],
) => {
  const dataCols = data.cols;
  const rows = data.rows;
  const columnOrderSettings: TableColumnOrderSetting[] = settings;

  if (columnOrderSettings) {
    const columnIds = columnOrderSettings.map(element =>
      dataCols.findIndex(
        item =>
          JSON.stringify(item.field_ref) === JSON.stringify(element.fieldRef),
      ),
    );

    if (Array.isArray(rows) && rows.length > 0) {
      return rows.map(row => columnIds.map(index => row[index]));
    }
  } else {
    return [];
  }
};

export const makeTreemapChartTree = (data: unknown): TreemapSeriesType[] => {
  if (Array.isArray(data) && data.length > 0) {
    const childLength: number = data[0].length;

    if (childLength < 2) {
      return [];
    }
    if (childLength === 2) {
      const childrens: TreemapSeriesType[] = [];
      data.forEach(row => {
        return childrens.push({ label: row[0], data: row[1] });
      });
      return childrens;
    }

    const setter: Set<string> = new Set();
    const parents: TreemapSeriesType[] = [];

    data.forEach(row => {
      setter.add(row[0]);
    });
    for (const name of setter) {
      const arrayOfChildren = data.filter(child => child.includes(name));
      arrayOfChildren.forEach(item => item.splice(0, 1));
      parents.push({
        label: name,
        children: makeTreemapChartTree(arrayOfChildren),
      });
    }
    return parents;
  }
  return [];
};
