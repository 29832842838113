import type { TreemapSeriesData } from "@toast-ui/chart/types/options";
import { TreemapChart } from "@toast-ui/react-chart";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { t } from "ttag";

import { extractRemappedColumns } from "metabase/visualizations";
import "@toast-ui/chart/dist/toastui-chart.css";
import { NoBreakoutError } from "metabase/visualizations/lib/errors";
import type { RemappingHydratedChartData } from "metabase/visualizations/types";
import type {
  TableColumnOrderSetting,
  VisualizationSettings,
} from "metabase-types/api";

import { settings } from "./settings";
import "./style.css";
import type {
  InitialOptions,
  MapTreeChartSize,
  Theme,
  TooltipOptions,
  TreeMapProps,
} from "./types";
import {
  buildChartDataWithColumnsSettings,
  makeTreemapChartTree,
} from "./utils";

function TreeMap({ settings, rawSeries, width, height }: TreeMapProps) {
  const [mode, setMode] = useState(false);
  const treeRef = useRef<TreemapChart>(null);

  const showTooltipLabel: boolean = settings["treemap.chart_tooltip_label"];
  const showTooltipValue: boolean = settings["treemap.chart_tooltip_value"];
  const showDataLabels: boolean = settings["treemap.chart_data_labels"];
  const dataLabelFontSize: number = settings["treemap.chart_font_size"];

  const theme: Theme = {
    chart: { backgroundColor: "rgba(255, 255, 255, 0.0)" },
    series: {
      dataLabels: {
        fontSize: dataLabelFontSize,
        color: "#ffffff",
      },
    },
  };
  const initialOptions: InitialOptions = {
    chart: {
      width: width || 300,
      height: height - 15 || 300,
    },
    tooltip: { template: () => `<div class="hidden"></div>` },
    series: {
      zoomable: true,
    },
    theme,
  };

  const [options, setOptions] = useState(initialOptions);

  const [chartSeries] = useMemo(() => {
    return rawSeries;
  }, [rawSeries]);

  const data = useMemo(
    () =>
      extractRemappedColumns(chartSeries.data) as RemappingHydratedChartData,
    [chartSeries.data],
  );

  const columnOrderSettings: TableColumnOrderSetting[] =
    settings["treemap.chart_columns"];

  const dataForDisplay = buildChartDataWithColumnsSettings(
    data,
    columnOrderSettings,
  );

  const chartData: TreemapSeriesData = useMemo(() => {
    return { series: makeTreemapChartTree(dataForDisplay) };
  }, [dataForDisplay]);

  useEffect(() => {
    const mapTreeChartSize: MapTreeChartSize = {
      width: width,
      height: height - 15,
    };

    const tooltipInnerHtml: TooltipOptions = {
      template: model => {
        const tooltipData = showTooltipValue
          ? `<span>${model.data[0].label}</span>
           <span>${model.data[0].value}</span>`
          : `<span>${model.data[0].label}</span>`;
        return `
        <div class="toastui-chart-tooltip-series-wrapper">
          <div class="toastui-chart-tooltip-series">
            <span class="toastui-chart-series-name">
              ${tooltipData}
            </span>
          </div>
        </div>`;
      },
    };
    const tooltipSettings: TooltipOptions = showTooltipLabel
      ? tooltipInnerHtml
      : {
          template: () => {
            return `<div class="hidden"></div>`;
          },
        };

    const seriesSettings = {
      zoomable: true,
      dataLabels: {
        visible: showDataLabels ? true : false,
      },
    };

    if (treeRef.current) {
      treeRef.current.getInstance().updateOptions({
        chart: mapTreeChartSize,
        series: seriesSettings,
        tooltip: tooltipSettings,
      });
      setMode(mode => !mode);
    }

    const theme: Theme = {
      chart: { backgroundColor: "rgba(255, 255, 255, 0.1)" },
      series: {
        dataLabels: {
          fontSize: dataLabelFontSize,
          color: "#ffffff",
        },
      },
    };

    setOptions({
      chart: mapTreeChartSize,
      tooltip: tooltipSettings,
      series: seriesSettings,
      theme,
    });

    const tooltipChildren = treeRef.current
      ?.getRootElement()
      .getElementsByClassName("toastui-chart-tooltip-container")[0];

    if (!showTooltipLabel) {
      tooltipChildren?.classList.add("hidden");
    } else {
      tooltipChildren?.classList.remove("hidden");
    }
  }, [
    width,
    height,
    showTooltipLabel,
    showTooltipValue,
    showDataLabels,
    dataLabelFontSize,
  ]);

  return (
    <SwitchTransition>
      <CSSTransition
        key={mode ? "mode" : "!mode"}
        treeRef={treeRef}
        timeout={300}
        addEndListener={(node, done) =>
          node.addEventListener("transitionend", done, true)
        }
        classNames="fade"
      >
        <TreemapChart ref={treeRef} data={chartData} options={options} />
      </CSSTransition>
    </SwitchTransition>
  );
}

// eslint-disable-next-line import/no-default-export
export default Object.assign(TreeMap, {
  uiName: t`TreeMap`,
  identifier: "treemap",
  noun: t`treemap`,
  iconName: "treemap",
  hidden: false,
  settings,
  checkRenderable([{ data }]: unknown, settings: VisualizationSettings) {
    const isLastChildIsNumeric = (data: unknown) => {
      if (Array.isArray(data) && data.length > 0) {
        const child = data[0];
        if (child.length < 2 || typeof child[child.length - 1] !== "number") {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    };
    const isValidData = isLastChildIsNumeric(
      buildChartDataWithColumnsSettings(
        data,
        settings["treemap.chart_columns"],
      ),
    );
    if (!isValidData) {
      throw new NoBreakoutError(t`The last row must contain numeric values`);
    }
  },
});
export { TreeMap };
