var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var ID,OD,Oga,Pga,Qga,Rga,Sga,Tga,dE;
$CLJS.GD=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.ez([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.HD=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.fD(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)};ID=function(a){return $CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M};
$CLJS.KD=function(a){var b=function(){var c=ID(a);return c?(c=$CLJS.sd($CLJS.dd(a)))?(c=$CLJS.li.h($CLJS.dd(a)),$CLJS.n(c)?c:$CLJS.tA.h($CLJS.dd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.JD.h(a)};
OD=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wi,new $CLJS.h(null,2,[$CLJS.ts,"valid MBQL clause",$CLJS.Es,function(c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.$i);return["invalid MBQL clause: ",$CLJS.xh.l($CLJS.H([c]))].join("")}],null),$CLJS.Oe(ID)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wi,new $CLJS.h(null,1,[$CLJS.ts,b],null),function(c){var d=$CLJS.MD;
$CLJS.n(d)?c=d:(c=$CLJS.KD(c),c=ND(c,a));return c}],null)],null)};$CLJS.PD=new $CLJS.M(null,"right-join","right-join",-56349359);Oga=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);Pga=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Qga=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);
$CLJS.QD=new $CLJS.M(null,"fields","fields",-1932066230);$CLJS.RD=new $CLJS.M(null,"expr","expr",745722291);$CLJS.SD=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.TD=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.UD=new $CLJS.M(null,"alias","alias",-2039751630);
$CLJS.VD=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);$CLJS.WD=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.XD=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.YD=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);
Rga=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.ZD=new $CLJS.M(null,"left-join","left-join",-672831855);$CLJS.$D=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.aE=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);
$CLJS.bE=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);Sga=new $CLJS.r("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);Tga=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.cE=new $CLJS.M(null,"inner-join","inner-join",659431740);
dE=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);$CLJS.LD=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);$CLJS.eE=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.fE=new $CLJS.M(null,"full-join","full-join",1305476385);
$CLJS.gE=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.hE=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);var ND;$CLJS.JD=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Li,$CLJS.gB],null),$CLJS.Li,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.XA(f);return $CLJS.E.g(k,$CLJS.sA)?$CLJS.Za(f):k},e,a,b,c,d)}();
$CLJS.Y(Pga,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.XD],null),$CLJS.yD],null));$CLJS.JD.m(null,$CLJS.Oh,function(a){throw $CLJS.Uh($CLJS.fD("{0}: Don''t know how to determine the type of {1}",$CLJS.H([Sga,$CLJS.xh.l($CLJS.H([a]))])),new $CLJS.h(null,1,[$CLJS.RD,a],null));});$CLJS.JD.m(null,$CLJS.aE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.KD(a)});
ND=function ND(a,b){return $CLJS.qd(a)?$CLJS.Me(function(d){return ND.g?ND.g(d,b):ND.call(null,d,b)},a):$CLJS.qd(b)?$CLJS.Me(function(d){return ND.g?ND.g(a,d):ND.call(null,a,d)},b):$CLJS.E.g(a,$CLJS.XD)?!0:$CLJS.Dz(a,b)};$CLJS.MD=!1;$CLJS.Y($CLJS.VD,OD($CLJS.fj,"expression returning a boolean"));$CLJS.Y($CLJS.eE,OD($CLJS.qj,"expression returning a string"));$CLJS.Y($CLJS.hE,OD($CLJS.Ui,"expression returning an integer"));$CLJS.Y(Qga,OD($CLJS.LC,"expression returning a non-integer real number"));
$CLJS.Y($CLJS.gE,OD($CLJS.hj,"expression returning a number"));$CLJS.Y(Rga,OD($CLJS.vk,"expression returning a date"));$CLJS.Y(Tga,OD($CLJS.Kk,"expression returning a time"));$CLJS.Y(Oga,OD($CLJS.Bk,"expression returning a date time"));$CLJS.Y($CLJS.TD,OD($CLJS.mj,"expression returning a date, time, or date time"));$CLJS.iE=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.hj,null,$CLJS.qj,null,$CLJS.mj,null,$CLJS.fj,null],null),null);$CLJS.Y($CLJS.bE,OD($CLJS.iE,"an expression that can be compared with :\x3e or :\x3c"));
var Uga=new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.BB,null,$CLJS.hj,null,$CLJS.qj,null,$CLJS.fC,null,$CLJS.mj,null,$CLJS.Yi,null,$CLJS.fj,null,$CLJS.JC,null],null),null);$CLJS.gA.g($CLJS.qj,dE);$CLJS.gA.g($CLJS.fC,dE);$CLJS.Y(dE,OD(dE,"expression returning something emptyable (e.g. a string or BSON ID)"));$CLJS.Y($CLJS.$D,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,OD(Uga,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.Y($CLJS.WD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,OD($CLJS.Yi,"any type of expression")],null));
$CLJS.Y($CLJS.SD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xi,new $CLJS.h(null,1,[$CLJS.oi,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.ur,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oD,$CLJS.oj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jq,$CLJS.ur],null)],null)],null)],null));