import { t } from "ttag";

import type { WidgetSettings } from "metabase-types/api/widgets/widget";

type TreeMapCommonSettingsKeys =
  | "treemap.chart_tooltip_label"
  | "treemap.chart_tooltip_value"
  | "treemap.chart_data_labels"
  | "treemap.chart_font_size";

export type TreeMapSettings = WidgetSettings<TreeMapCommonSettingsKeys>;

export const TREEMAP_SETTINGS: TreeMapSettings = {
  "treemap.chart_tooltip_label": {
    section: t`Display`,
    title: t`Tooltip label`,
    widget: "toggle",
    default: true,
  },
  "treemap.chart_tooltip_value": {
    section: t`Display`,
    title: t`Tooltip value`,
    widget: "toggle",
    default: false,
  },

  "treemap.chart_data_labels": {
    section: t`Display`,
    title: t`Data labels`,
    widget: "toggle",
    default: false,
  },
  "treemap.chart_font_size": {
    section: t`Display`,
    title: t`Cell font size`,
    widget: "number",
    default: 12.5,
  },
};
