var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.drill_thru.automatic_insights.js");require("./metabase.lib.drill_thru.column_filter.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.drill_thru.distribution.js");require("./metabase.lib.drill_thru.fk_details.js");require("./metabase.lib.drill_thru.fk_filter.js");require("./metabase.lib.drill_thru.pk.js");require("./metabase.lib.drill_thru.zoom.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.util.malli.js");require("./metabase.lib.drill_thru.pivot.js");require("./metabase.lib.drill_thru.quick_filter.js");require("./metabase.lib.drill_thru.sort.js");require("./metabase.lib.drill_thru.summarize_column.js");require("./metabase.lib.drill_thru.summarize_column_by_time.js");require("./metabase.lib.drill_thru.underlying_records.js");require("./metabase.lib.drill_thru.zoom_in_bins.js");require("./metabase.lib.drill_thru.zoom_in_geographic.js");require("./metabase.lib.drill_thru.zoom_in_timeseries.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var j8,k8,l8,m8,n8,o8,Wta,p8,Xta,Yta,q8,r8,Zta,s8,t8,$ta,u8,aua,bua,v8,w8,x8,y8,cua,z8,dua,eua,fua,gua,A8,hua,iua,B8,jua,kua,lua,mua,nua,C8,oua,pua,qua,rua,sua,tua,uua,D8,vua,wua,xua,yua,zua,Aua,Bua,Cua,Dua,Eua,Fua,Gua,Hua,Iua,Jua,Kua,Lua,E8,F8,Mua,Nua,Oua,G8,Pua,Qua,Rua,Sua,H8,Tua,Uua,Vua,I8,Wua,Xua,Yua,Zua,$ua;$CLJS.i8=function(a){return $CLJS.cc($CLJS.cb(function(b,c){return $CLJS.Zf.j(b,c,$CLJS.J.j(b,c,0)+1)},$CLJS.ac($CLJS.N),a))};
j8=function(a){var b=$CLJS.XZ(a);return $CLJS.n(b)?$CLJS.ff($CLJS.JZ,$CLJS.c_(a,b)):$CLJS.xf};
k8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.xi),e=$CLJS.J.g(c,$CLJS.c6),f=$CLJS.J.g(c,$CLJS.GK),k=$CLJS.J.g(c,$CLJS.$i);return $CLJS.n(function(){var l=$CLJS.U5(a,b);return l?(l=$CLJS.Be(f),$CLJS.n(l)?(l=$CLJS.n(d)?null!=k:d,l=$CLJS.n(l)?l:null==d&&null==k,$CLJS.n(l)?$CLJS.Ra($CLJS.n(d)?$CLJS.KZ(d):d):l):l):l}())?new $CLJS.h(null,6,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.V6,$CLJS.P6,"number"!==typeof k||0>k?2:k,$CLJS.LY,function(){var l=null==a?null:$CLJS.XZ(a);l=null==l?null:$CLJS.b_(a,l);
$CLJS.n(l)||(l=null==a?null:$CLJS.I1(a),l=null==l?null:$CLJS.d_(a,l));return $CLJS.n(l)?$CLJS.j_.j(a,b,l):null}(),$CLJS.GK,f,$CLJS.c6,e],null):null};
l8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.xi),e=$CLJS.J.g(c,$CLJS.c6),f=$CLJS.J.g(c,$CLJS.GK),k=$CLJS.J.g(c,$CLJS.$i);return $CLJS.n(function(){var l=$CLJS.U5(a,b);return l?(l=$CLJS.Ra(d)||null!=k)?(l=$CLJS.G7(a,$CLJS.Ata),$CLJS.n(l)?$CLJS.Be(f):l):l:l}())?new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.z6,$CLJS.c6,e,$CLJS.GK,f],null):null};
m8=function(a,b,c,d){var e=$CLJS.q5(a,$CLJS.CV(a,b)),f=$CLJS.Vj.g($CLJS.LJ.h(c),$CLJS.jK)?new $CLJS.h(null,2,[$CLJS.EE,a,$CLJS.DK,b],null):$CLJS.n(e)?new $CLJS.h(null,2,[$CLJS.EE,a,$CLJS.DK,e],null):new $CLJS.h(null,2,[$CLJS.EE,$CLJS.S7(a),$CLJS.DK,-1],null),k=$CLJS.$2.g($CLJS.EE.h(f),$CLJS.DK.h(f));a=function(){var l=$CLJS.S1.v($CLJS.EE.h(f),$CLJS.DK.h(f),d,k);if($CLJS.n(l))return l;l=$CLJS.B0.h(c);return $CLJS.n(l)?$CLJS.rG(function(m){return $CLJS.E.g($CLJS.B0.h(m),$CLJS.B0.h(c))},k):l}();return $CLJS.R.j(f,
$CLJS.xi,a)};n8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.xi);var e=$CLJS.J.g(d,$CLJS.c6);d=$CLJS.J.g(d,$CLJS.$i);var f=$CLJS.U5(a,b);d=f?$CLJS.n(c)?null==d&&!$CLJS.KZ(c):c:f;return $CLJS.n(d)?(d=$CLJS.BZ(c)?null:$CLJS.R.j($CLJS.z($CLJS.p2(c)),$CLJS.Vs,$CLJS.OI),$CLJS.ok.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.t6,$CLJS.r6,d],null),m8(a,b,c,e)]))):null};
o8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.xi);d=$CLJS.J.g(d,$CLJS.$i);var e=$CLJS.U5(a,b);a=e?$CLJS.n(c)?null==d&&$CLJS.Vj.g($CLJS.LJ.h(c),$CLJS.jK)&&!$CLJS.JZ(c)&&!$CLJS.KZ(c)&&!$CLJS.UZ(c)&&!$CLJS.HZ(c)&&$CLJS.Ra($CLJS.y($CLJS.S5.j(a,b,c))):c:e;return $CLJS.n(a)?new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.T6,$CLJS.xi,c],null):null};
Wta=function(a,b,c){c=$CLJS.O(c);b=$CLJS.J.g(c,$CLJS.xi);c=$CLJS.J.g(c,$CLJS.$i);return $CLJS.IZ(b)&&null!=c&&$CLJS.Vj.g(c,$CLJS.LK)?new $CLJS.h(null,5,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.S6,$CLJS.xi,b,$CLJS.d6,c,$CLJS.Y5,1<$CLJS.D(j8(a))],null):null};
p8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.xi);var e=$CLJS.J.g(d,$CLJS.c6);d=$CLJS.J.g(d,$CLJS.$i);if($CLJS.n($CLJS.n(c)?null!=d&&$CLJS.Vj.g(d,$CLJS.LK)&&$CLJS.U5(a,b)&&!$CLJS.JZ(c)&&$CLJS.IZ(c):c)){var f=null==a?null:$CLJS.XZ(a);f=null==f?null:$CLJS.b_(a,f);$CLJS.n(f)||(f=null==a?null:$CLJS.I1(a),f=null==f?null:$CLJS.d_(a,f));return new $CLJS.h(null,5,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.x6,$CLJS.ME,$CLJS.nV(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Gq,$CLJS.N,e,d],null)),$CLJS.TE,$CLJS.j_.v(a,
b,c,$CLJS.k_),$CLJS.LY,$CLJS.j_.j(a,0,f)],null)}return null};
Xta=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.xi),f=$CLJS.J.g(d,$CLJS.$i),k=$CLJS.J.g(d,$CLJS.Yh);if(null!=f&&$CLJS.U5(a,b)&&1<$CLJS.D(j8(a))&&!$CLJS.IZ(e)){if($CLJS.JZ(e))return null!=f&&$CLJS.Vj.g(f,$CLJS.LK)?new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.H6,$CLJS.GK,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.xi,e,$CLJS.$i,f],null)],null)],null):null;var l=j8(a);a=function(){return function u(t){return new $CLJS.ie(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var A=
x;if($CLJS.vd(A)){var C=$CLJS.ic(A),G=$CLJS.D(C),K=$CLJS.le(G);return function(){for(var T=0;;)if(T<G){var da=$CLJS.hd(C,T),oa=$CLJS.$i.h($CLJS.rG(function(Ma,sb,Qa){return function(Xa){return $CLJS.E.g($CLJS.U.h($CLJS.xi.h(Xa)),$CLJS.U.h(Qa))}}(T,v,da,C,G,K,A,x,l,c,d,d,e,f,k),k));$CLJS.n(oa)&&$CLJS.pe(K,new $CLJS.h(null,2,[$CLJS.xi,da,$CLJS.$i,oa],null));T+=1}else return!0}()?$CLJS.oe($CLJS.qe(K),u($CLJS.jc(A))):$CLJS.oe($CLJS.qe(K),null)}var S=$CLJS.z(A),X=$CLJS.$i.h($CLJS.rG(function(T,da){return function(oa){return $CLJS.E.g($CLJS.U.h($CLJS.xi.h(oa)),
$CLJS.U.h(da))}}(v,S,A,x,l,c,d,d,e,f,k),k));if($CLJS.n(X))return $CLJS.ae(new $CLJS.h(null,2,[$CLJS.xi,S,$CLJS.$i,X],null),u($CLJS.Hc(A)));v=$CLJS.Hc(A)}else return null}},null,null)}(l)}();return $CLJS.y(a)?new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.H6,$CLJS.GK,$CLJS.yf($CLJS.c1(function(m){return $CLJS.Bz(m,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,$CLJS.Di],null))},a))],null):null}return null};
Yta=function(a,b,c){var d=$CLJS.O(c);b=$CLJS.J.g(d,$CLJS.xi);c=$CLJS.J.g(d,$CLJS.$i);d=$CLJS.J.g(d,$CLJS.Yh);if(null==c||1<$CLJS.D(j8(a)))return null;if($CLJS.JZ(b))return $CLJS.E.g(c,$CLJS.LK)?null:new $CLJS.h(null,5,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.j7,$CLJS.xi,b,$CLJS.d6,c,$CLJS.Y5,!1],null);a=j8(a);var e=$CLJS.I(a,0,null);a=$CLJS.$i.h($CLJS.rG(function(f){return $CLJS.E.g($CLJS.U.h($CLJS.xi.h(f)),$CLJS.U.h(e))},d));return $CLJS.n(a)?null==a?null:new $CLJS.h(null,5,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,
$CLJS.j7,$CLJS.xi,e,$CLJS.d6,a,$CLJS.Y5,!1],null):null};q8=function(a,b,c){return $CLJS.Me(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)},new $CLJS.P(null,3,5,$CLJS.Q,[Wta,Xta,Yta],null))};r8=function(a,b,c,d){var e=$CLJS.O(c);c=$CLJS.J.g(e,$CLJS.xi);e=$CLJS.J.g(e,$CLJS.$i);var f=$CLJS.U5(a,b);c=f?$CLJS.n(c)?null!=e&&$CLJS.E.g($CLJS.LJ.h(c),$CLJS.jK):c:f;return $CLJS.n(c)?$CLJS.ff(d,$CLJS.R5.g(a,b)):null};
Zta=function(a,b){var c=$CLJS.i8($CLJS.cf.g(function(d){d=$CLJS.h_.j(a,b,d);return $CLJS.BZ(d)?$CLJS.AO:$CLJS.NZ(d)?$CLJS.T7:$CLJS.GZ(d)?$CLJS.BF:null},$CLJS.P5.g(a,b)));return $CLJS.E.g(new $CLJS.h(null,1,[$CLJS.AO,1],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.BF,null,$CLJS.n6,null],null),null):$CLJS.E.g(new $CLJS.h(null,2,[$CLJS.AO,1,$CLJS.BF,1],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.BF,null,$CLJS.n6,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.T7,1],null),c)?new $CLJS.Rg(null,
new $CLJS.h(null,2,[$CLJS.BF,null,$CLJS.Fx,null],null),null):$CLJS.E.g($CLJS.N,c)?new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.BF,null,$CLJS.Fx,null,$CLJS.n6,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.BF,1],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.BF,null,$CLJS.Fx,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.BF,2],null),c)?new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.BF,null,$CLJS.Fx,null],null),null):$CLJS.Sg};
s8=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.xi);c=$CLJS.J.g(d,$CLJS.GK);var f=$CLJS.J.g(d,$CLJS.$i);if($CLJS.n(function(){var m=$CLJS.U5(a,b);return m?$CLJS.n(e)?null!=f&&$CLJS.E.g($CLJS.LJ.h(e),$CLJS.jK)&&0<$CLJS.D($CLJS.r4.g(a,b)):e:m}())){var k=Zta(a,b),l=$CLJS.Wf.g($CLJS.N,function(){return function u(t){return new $CLJS.ie(null,function(){for(var v=t;;)if(v=$CLJS.y(v)){if($CLJS.vd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),C=$CLJS.le(A);a:for(var G=0;;)if(G<A){var K=$CLJS.hd(x,G),S=$CLJS.J.g($CLJS.U7,
K);S=r8(a,b,d,S);$CLJS.n($CLJS.Be(S))&&C.add(new $CLJS.P(null,2,5,$CLJS.Q,[K,S],null));G+=1}else{x=!0;break a}return x?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}C=$CLJS.z(v);x=$CLJS.J.g($CLJS.U7,C);x=r8(a,b,d,x);if($CLJS.n($CLJS.Be(x)))return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[C,x],null),u($CLJS.Hc(v)));v=$CLJS.Hc(v)}else return null},null,null)}(k)}());return $CLJS.od(l)?null:new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.b7,$CLJS.GK,c,$CLJS.j6,l],null)}return null};
t8=function(a,b){return $CLJS.nV($CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.N],null),b))};
$ta=function(a,b){var c=$CLJS.oV(a);if($CLJS.KZ(a))return $CLJS.xf;if($CLJS.E.g(b,$CLJS.LK))return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.U,"\x3d",$CLJS.ME,t8($CLJS.JE,$CLJS.H([c]))],null),new $CLJS.h(null,2,[$CLJS.U,"≠",$CLJS.ME,t8($CLJS.VE,$CLJS.H([c]))],null)],null);if($CLJS.CZ(a)||$CLJS.BZ(a))return a=function f(e){return new $CLJS.ie(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var x=0;;)if(x<
m){var A=$CLJS.hd(l,x),C=$CLJS.I(A,0,null);A=$CLJS.I(A,1,null);var G=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.zq,null,$CLJS.Dq,null],null),null);G=G.h?G.h(C):G.call(null,C);G=(G=$CLJS.Ra(G))?G:$CLJS.MI(c,b);$CLJS.n(G)&&(C=new $CLJS.h(null,2,[$CLJS.U,A,$CLJS.ME,t8(C,$CLJS.H([c,b]))],null),t.add(C));x+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);if($CLJS.n(function(){var x=new $CLJS.Rg(null,new $CLJS.h(null,
2,[$CLJS.zq,null,$CLJS.Dq,null],null),null);x=x.h?x.h(v):x.call(null,v);return(x=$CLJS.Ra(x))?x:$CLJS.MI(c,b)}()))return $CLJS.ae(new $CLJS.h(null,2,[$CLJS.U,u,$CLJS.ME,t8(v,$CLJS.H([c,b]))],null),f($CLJS.Hc(k)));k=$CLJS.Hc(k)}else return null},null,null)},a(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dq,"\x3c"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zq,"\x3e"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zF,"≠"],null)],null));
if($CLJS.DZ(a)&&($CLJS.UZ(a)||$CLJS.HZ(a)))return a=function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.U,v,$CLJS.ME,t8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}l=$CLJS.z(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);
return $CLJS.ae(new $CLJS.h(null,2,[$CLJS.U,l,$CLJS.ME,t8(t,$CLJS.H([c,b]))],null),f($CLJS.Hc(k)))}return null}},null,null)},a(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hF,"contains"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,"does-not-contain"],null)],null));a=function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,
1,null);x=new $CLJS.h(null,2,[$CLJS.U,v,$CLJS.ME,t8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}l=$CLJS.z(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.ae(new $CLJS.h(null,2,[$CLJS.U,l,$CLJS.ME,t8(t,$CLJS.H([c,b]))],null),f($CLJS.Hc(k)))}return null}},null,null)};return a(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zF,"≠"],null)],
null))};u8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.xi);var e=$CLJS.J.g(d,$CLJS.c6);d=$CLJS.J.g(d,$CLJS.$i);var f=(f=$CLJS.U5(a,b))?$CLJS.n(c)?null!=d&&!$CLJS.KZ(c)&&!$CLJS.JZ(c)&&!$CLJS.IZ(c):c:f;return $CLJS.n(f)?(a=m8(a,b,c,e),$CLJS.ok.l($CLJS.H([new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.b6,$CLJS.BK,$ta($CLJS.xi.h(a),d),$CLJS.$i,d],null),a]))):null};
aua=function(a,b,c){return $CLJS.rG(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);d=$CLJS.I(d,2,null);return $CLJS.S1.v(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null))},$CLJS.Y7.g(a,b))};bua=function(a,b,c){a=aua(a,b,c);return $CLJS.n(a)?(b=$CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),b):null};
v8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.xi),e=$CLJS.J.g(c,$CLJS.c6),f=$CLJS.J.g(c,$CLJS.$i);if($CLJS.n(function(){var l=$CLJS.U5(a,b);return l?$CLJS.n(d)?null==f&&!$CLJS.KZ(d):d:l}())&&$CLJS.n($CLJS.S1.v(a,b,e,$CLJS.Z7.g(a,b)))){var k=bua(a,b,d);return new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.D6,$CLJS.xi,d,$CLJS.G6,function(){switch(k instanceof $CLJS.M?k.S:null){case "asc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ZE],null);case "desc":return new $CLJS.P(null,1,5,$CLJS.Q,
[$CLJS.fF],null);default:return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fF,$CLJS.ZE],null)}}()],null)}return null};
w8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.xi);d=$CLJS.J.g(d,$CLJS.$i);var e=$CLJS.U5(a,b);a=e?$CLJS.n(c)?null==d&&!$CLJS.KZ(c)&&$CLJS.Vj.g($CLJS.LJ.h(c),$CLJS.jK)&&$CLJS.Ra($CLJS.y($CLJS.S5.j(a,b,c))):c:e;return $CLJS.n(a)?(a=$CLJS.bf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.NE],null),$CLJS.FZ(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uF,$CLJS.cF],null):null),new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.E6,$CLJS.xi,c,$CLJS.J6,a],null)):null};
x8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.xi);d=$CLJS.J.g(d,$CLJS.$i);var e=$CLJS.U5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.KZ(c)&&$CLJS.FZ(c)&&$CLJS.Vj.g($CLJS.LJ.h(c),$CLJS.jK):c:e;if($CLJS.n(d)&&(d=$CLJS.rG($CLJS.BZ,$CLJS.R5.g(a,b)),$CLJS.n(d)&&(e=$CLJS.rG($CLJS.Oh,$CLJS.v0.j(a,b,d)),$CLJS.n(e)))){var f=$CLJS.e3(d,e);return $CLJS.n($CLJS.HU($CLJS.cf.g($CLJS.oV,$CLJS.ae(f,$CLJS.P5.g(a,b)))))?new $CLJS.h(null,5,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.Z6,$CLJS.xi,c,$CLJS.XE,d,$CLJS.tk,$CLJS.s0.h(e)],
null):null}return null};
y8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.xi);d=$CLJS.J.g(d,$CLJS.$i);if($CLJS.n($CLJS.n(c)?d:c))if(b=$CLJS.z($CLJS.S5.j(a,b,c)),$CLJS.n(b))if(b=$CLJS.f5(b),$CLJS.n(b))if(a=$CLJS.I7(a,c,d),$CLJS.n(a)){var e=$CLJS.O(a);a=$CLJS.J.g(e,$CLJS.FK);var f=$CLJS.J.g(e,$CLJS.IK);e=$CLJS.J.g(e,$CLJS.zD);var k=$CLJS.AD.h(b);k=k instanceof $CLJS.M?k.S:null;switch(k){case "num-bins":case "default":return new $CLJS.h(null,6,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.I6,$CLJS.xi,c,$CLJS.FK,d,$CLJS.IK,d+e,$CLJS.A6,
new $CLJS.h(null,1,[$CLJS.AD,$CLJS.Oh],null)],null);case "bin-width":return new $CLJS.h(null,6,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.I6,$CLJS.xi,c,$CLJS.FK,a,$CLJS.IK,f,$CLJS.A6,$CLJS.Zj.j(b,$CLJS.zD,function(l){return l/10})],null);default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}else return null;else return null;else return null;else return null};
cua=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Yh),e=$CLJS.DV(a,b);b=$CLJS.Me(function(f){var k=$CLJS.rG($CLJS.SZ,f);return $CLJS.n(k)?(f=$CLJS.rG($CLJS.TZ,f),$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null):null):null},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U_.j(a,b,e),$CLJS.W_.j(a,b,e)],null));a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.n($CLJS.n(a)?b:a)?(e=function(f){return $CLJS.Me(function(k){var l=$CLJS.xi.h(k);l=$CLJS.n($CLJS.Di.h(f))?$CLJS.E.g($CLJS.Di.h(f),$CLJS.Di.h(l)):
$CLJS.E.g($CLJS.IY.h(f),$CLJS.IY.h(l));return $CLJS.n(l)?$CLJS.$i.h(k):null},d)},$CLJS.R.l(c,$CLJS.d8,a,$CLJS.H([$CLJS.e8,b,$CLJS.f8,e(a),$CLJS.c8,e(b)]))):null};
z8=function(a,b){var c=$CLJS.O(a);a=$CLJS.J.g(c,$CLJS.xi);var d=$CLJS.J.g(c,$CLJS.$i),e=$CLJS.J.g(c,$CLJS.d8);c=$CLJS.J.g(c,$CLJS.e8);return $CLJS.n(d)?new $CLJS.h(null,7,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,$CLJS.f7,$CLJS.h6,$CLJS.v6,$CLJS.xi,a,$CLJS.$i,d,$CLJS.y6,new $CLJS.h(null,2,[$CLJS.xi,e,$CLJS.zD,b],null),$CLJS.w6,new $CLJS.h(null,2,[$CLJS.xi,c,$CLJS.zD,b],null)],null):null};dua=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.xi);return $CLJS.n(null==b?null:$CLJS.QZ(b))?z8(a,10):null};
eua=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.xi);return $CLJS.n(null==b?null:$CLJS.PZ(b))?z8(a,1):null};fua=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.xi);return $CLJS.n(null==b?null:$CLJS.OZ(b))?z8(a,.1):null};
gua=function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.d8);var d=$CLJS.J.g(c,$CLJS.e8),e=$CLJS.J.g(c,$CLJS.f8);c=$CLJS.J.g(c,$CLJS.c8);if($CLJS.n($CLJS.n(e)?c:e)){var f=$CLJS.I7(a,b,e);if($CLJS.n(f)&&(f=$CLJS.O(f),f=$CLJS.J.g(f,$CLJS.zD),a=$CLJS.I7(a,d,c),$CLJS.n(a))){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.zD);var k=20<=f&&20<=a?new $CLJS.P(null,2,5,$CLJS.Q,[10,10],null):new $CLJS.P(null,2,5,$CLJS.Q,[f/10,a/10],null),l=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return new $CLJS.h(null,5,[$CLJS.Vs,$CLJS.MK,$CLJS.Ni,
$CLJS.f7,$CLJS.h6,$CLJS.$5,$CLJS.y6,new $CLJS.h(null,4,[$CLJS.xi,b,$CLJS.zD,l,$CLJS.oi,e,$CLJS.uj,e+f],null),$CLJS.w6,new $CLJS.h(null,4,[$CLJS.xi,d,$CLJS.zD,k,$CLJS.oi,c,$CLJS.uj,c+a],null)],null)}}return null};A8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.$i);if($CLJS.n(d)){var e=cua(a,b,c);return $CLJS.n(e)?$CLJS.Me(function(f){return f.h?f.h(e):f.call(null,e)},new $CLJS.P(null,4,5,$CLJS.Q,[dua,eua,fua,$CLJS.Qe(gua,a)],null)):null}return null};
hua=function(a,b,c){return $CLJS.z(function(){return function f(e){return new $CLJS.ie(null,function(){for(var k=e;;){var l=$CLJS.y(k);if(l){var m=l,t=$CLJS.z(m);if($CLJS.n(function(){var u=$CLJS.F1(t,$CLJS.iF);return u?$CLJS.l2(t):u}())&&(l=$CLJS.y(function(u,v,x,A){return function K(G){return new $CLJS.ie(null,function(S,X){return function(){for(var T=G;;)if(T=$CLJS.y(T)){if($CLJS.vd(T)){var da=$CLJS.ic(T),oa=$CLJS.D(da),Ma=$CLJS.le(oa);return function(){for(var Ja=0;;)if(Ja<oa){var Ua=$CLJS.hd(da,
Ja),Ta=$CLJS.O(Ua);Ua=Ta;Ta=$CLJS.J.g(Ta,$CLJS.xi);var mb=$CLJS.S1.g(X,new $CLJS.P(null,1,5,$CLJS.Q,[Ta],null));Ta=$CLJS.n(mb)?$CLJS.E.g($CLJS.l2(X),$CLJS.l2(Ta)):mb;$CLJS.n(Ta)&&(Ua=$CLJS.R.j(Ua,$CLJS.c6,X),Ma.add(Ua));Ja+=1}else return!0}()?$CLJS.oe($CLJS.qe(Ma),K($CLJS.jc(T))):$CLJS.oe($CLJS.qe(Ma),null)}var sb=$CLJS.z(T),Qa=sb=$CLJS.O(sb),Xa=$CLJS.J.g(sb,$CLJS.xi);if($CLJS.n(function(){var Ja=$CLJS.S1.g(X,new $CLJS.P(null,1,5,$CLJS.Q,[Xa],null));return $CLJS.n(Ja)?$CLJS.E.g($CLJS.l2(X),$CLJS.l2(Xa)):
Ja}()))return $CLJS.ae($CLJS.R.j(Qa,$CLJS.c6,X),K($CLJS.Hc(T)));T=$CLJS.Hc(T)}else return null}}(u,v,x,A),null,null)}}(k,t,m,l)(c))))return $CLJS.bf.g(l,f($CLJS.Hc(k)));k=$CLJS.Hc(k)}else return null}},null,null)}($CLJS.P5.g(a,b))}())};
iua=function(a){a=a instanceof $CLJS.M?a.S:null;switch(a){case "quarter":return $CLJS.HD("See this year by quarter");case "month":return $CLJS.HD("See this quarter by month");case "week":return $CLJS.HD("See this month by week");case "day":return $CLJS.HD("See this week by day");case "hour":return $CLJS.HD("See this day by hour");case "minute":return $CLJS.HD("See this hour by minute");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}};
B8=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.GK);var d=(d=$CLJS.U5(a,b))?$CLJS.Be(c):d;return $CLJS.n(d)&&(a=hua(a,b,c),$CLJS.n(a)&&(a=$CLJS.O(a),b=$CLJS.J.g(a,$CLJS.$i),$CLJS.n(b)))?(b=$CLJS.xi.h(a),b=$CLJS.s0.h(b),b=$CLJS.n(b)?$CLJS.Cd($CLJS.Wg($CLJS.g8),b)?$CLJS.h8.h?$CLJS.h8.h(b):$CLJS.h8.call(null,b):null:null,$CLJS.n(b)?new $CLJS.h(null,5,[$CLJS.Vs,$CLJS.MK,$CLJS.kD,iua(b),$CLJS.Ni,$CLJS.d7,$CLJS.BE,a,$CLJS.Y6,b],null):null):null};
jua=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.GK);return $CLJS.Be(function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u);v=$CLJS.ok.l($CLJS.H([b,v]));t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.ok.l($CLJS.H([b,t])),f($CLJS.Hc(k)))}return null}},null,
null)}(c)}())};kua=new $CLJS.r(null,"metabase.lib.drill-thru.pivot","metabase.lib.drill-thru.pivot",109208756,null);lua=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-bins","metabase.lib.drill-thru.zoom-in-bins",477390405,null);mua=new $CLJS.r("metabase.lib.drill-thru.zoom-in-bins","zoom-in-binning-drill","metabase.lib.drill-thru.zoom-in-bins/zoom-in-binning-drill",-862934837,null);
nua=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column-by-time","metabase.lib.drill-thru.summarize-column-by-time",-369504986,null);C8=new $CLJS.r(null,"stage-number","stage-number",-112198111,null);oua=new $CLJS.r(null,"underlying-records-drill","underlying-records-drill",713740052,null);pua=new $CLJS.r("metabase.lib.drill-thru.zoom-in-timeseries","zoom-in-timeseries-drill","metabase.lib.drill-thru.zoom-in-timeseries/zoom-in-timeseries-drill",313862423,null);
qua=new $CLJS.r(null,"zoom-in-timeseries-drill","zoom-in-timeseries-drill",-1304664195,null);rua=new $CLJS.r(null,"distribution-drill","distribution-drill",-898205041,null);sua=new $CLJS.r(null,"sort-drill","sort-drill",600387798,null);tua=new $CLJS.M(null,"drill","drill",-1704624793);uua=new $CLJS.r("metabase.lib.drill-thru.quick-filter","quick-filter-drill","metabase.lib.drill-thru.quick-filter/quick-filter-drill",457872114,null);D8=new $CLJS.r(null,"_context","_context",-1238791322,null);
vua=new $CLJS.r(null,"metabase.lib.drill-thru.distribution","metabase.lib.drill-thru.distribution",-514507852,null);wua=new $CLJS.r(null,"zoom-in-binning-drill","zoom-in-binning-drill",1550598377,null);xua=new $CLJS.r(null,"metabase.lib.drill-thru.object-details","metabase.lib.drill-thru.object-details",1788168194,null);yua=new $CLJS.r(null,"automatic-insights-drill","automatic-insights-drill",-538289251,null);
zua=new $CLJS.r("metabase.lib.drill-thru.zoom-in-geographic","zoom-in-geographic-drill","metabase.lib.drill-thru.zoom-in-geographic/zoom-in-geographic-drill",754461586,null);Aua=new $CLJS.r("metabase.lib.drill-thru.distribution","distribution-drill","metabase.lib.drill-thru.distribution/distribution-drill",-2103693273,null);Bua=new $CLJS.r(null,"fk-filter-drill","fk-filter-drill",-1258190784,null);
Cua=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column","metabase.lib.drill-thru.summarize-column",490384430,null);Dua=new $CLJS.r(null,"pivot-drill","pivot-drill",-1794856768,null);Eua=new $CLJS.r(null,"metabase.lib.drill-thru.quick-filter","metabase.lib.drill-thru.quick-filter",671398986,null);Fua=new $CLJS.r(null,"metabase.lib.drill-thru.underlying-records","metabase.lib.drill-thru.underlying-records",-827008688,null);
Gua=new $CLJS.r("metabase.lib.drill-thru.summarize-column-by-time","summarize-column-by-time-drill","metabase.lib.drill-thru.summarize-column-by-time/summarize-column-by-time-drill",391970943,null);Hua=new $CLJS.r(null,"summarize-column-drill","summarize-column-drill",-1961542613,null);Iua=new $CLJS.r(null,"column-filter-drill","column-filter-drill",-1088669153,null);
Jua=new $CLJS.r("metabase.lib.drill-thru.underlying-records","underlying-records-drill","metabase.lib.drill-thru.underlying-records/underlying-records-drill",1176519262,null);Kua=new $CLJS.r(null,"metabase.lib.drill-thru.sort","metabase.lib.drill-thru.sort",961185366,null);Lua=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-geographic","metabase.lib.drill-thru.zoom-in-geographic",-1562146002,null);E8=new $CLJS.r(null,"dimensions","dimensions",1385713430,null);
F8=new $CLJS.M(null,"return-drills-for-dimensions?","return-drills-for-dimensions?",-1759804348);Mua=new $CLJS.r("metabase.lib.drill-thru.column-filter","column-filter-drill","metabase.lib.drill-thru.column-filter/column-filter-drill",-1130871894,null);Nua=new $CLJS.r(null,"object-detail-drill","object-detail-drill",-613919053,null);Oua=new $CLJS.r(null,"metabase.lib.drill-thru.column-filter","metabase.lib.drill-thru.column-filter",23504270,null);
G8=new $CLJS.r(null,"context","context",810340414,null);Pua=new $CLJS.r(null,"zoom-in-geographic-drill","zoom-in-geographic-drill",312914971,null);Qua=new $CLJS.r("metabase.lib.drill-thru.automatic-insights","automatic-insights-drill","metabase.lib.drill-thru.automatic-insights/automatic-insights-drill",1471807986,null);Rua=new $CLJS.r(null,"summarize-column-by-time-drill","summarize-column-by-time-drill",1117784387,null);
Sua=new $CLJS.r("metabase.lib.drill-thru.object-details","object-detail-drill","metabase.lib.drill-thru.object-details/object-detail-drill",-1376576725,null);H8=new $CLJS.r(null,"column-ref","column-ref",-636247393,null);Tua=new $CLJS.r("metabase.lib.drill-thru.pivot","pivot-drill","metabase.lib.drill-thru.pivot/pivot-drill",1004065694,null);Uua=new $CLJS.r(null,"metabase.lib.drill-thru.fk-filter","metabase.lib.drill-thru.fk-filter",-1939155669,null);
Vua=new $CLJS.r("metabase.lib.drill-thru.summarize-column","summarize-column-drill","metabase.lib.drill-thru.summarize-column/summarize-column-drill",-894275347,null);I8=new $CLJS.r(null,"query","query",352022017,null);Wua=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-timeseries","metabase.lib.drill-thru.zoom-in-timeseries",105846904,null);Xua=new $CLJS.r(null,"metabase.lib.drill-thru.automatic-insights","metabase.lib.drill-thru.automatic-insights",912989182,null);
Yua=new $CLJS.r("metabase.lib.drill-thru.fk-filter","fk-filter-drill","metabase.lib.drill-thru.fk-filter/fk-filter-drill",-716335012,null);Zua=new $CLJS.r("metabase.lib.drill-thru.sort","sort-drill","metabase.lib.drill-thru.sort/sort-drill",2019212116,null);$ua=new $CLJS.r(null,"quick-filter-drill","quick-filter-drill",534264366,null);var ava;$CLJS.R_.m(null,$CLJS.MK,function(a,b,c){return $CLJS.X5.j(a,b,c)});
ava=new $CLJS.P(null,14,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return l8},Qua,$CLJS.zg([$CLJS.Ok,$CLJS.Ai,$CLJS.U,$CLJS.yj,$CLJS.ei,$CLJS.xi,$CLJS.Oi,$CLJS.Gj,$CLJS.di,$CLJS.vj,$CLJS.xj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.JK,$CLJS.pr,$CLJS.m6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.C6],null)],null),Xua,yua,"metabase/lib/drill_thru/automatic_insights.cljc",34,1,11,11,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[I8,C8,new $CLJS.h(null,
1,[$CLJS.io,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Xy,H8,E8,$CLJS.yi],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru]\n          \n\n  Automatic insights appears:\n  - When clicking on a value with a breakout - eg. a point in a time series, a cell of a table, a bar or pie slice\n  - Or when clicking a pivot cell, with a value but no column.\n  - Or when clicking a chart legend, in which case there\'s no column or value set.\n  - There must be at least 1 breakout\n  - X-rays must be enabled (check the settings)\n\n  There are two forms: X-ray, and "Compare to the rest". This is a simple user choice and does not need extra data.',
$CLJS.n(l8)?l8.H:null])),F8,!1],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return n8},Mua,$CLJS.zg([$CLJS.Ok,$CLJS.Ai,$CLJS.U,$CLJS.yj,$CLJS.ei,$CLJS.xi,$CLJS.Oi,$CLJS.Gj,$CLJS.di,$CLJS.vj,$CLJS.xj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.JK,$CLJS.pr,$CLJS.m6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.p6],null)],null),Oua,Iua,"metabase/lib/drill_thru/column_filter.cljc",29,1,82,82,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[I8,C8,new $CLJS.h(null,
1,[$CLJS.io,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xy,H8,$CLJS.yi],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.column-filter]\n          \n\n  Filtering at the column level, based on its type. Displays a submenu of eg. "Today", "This Week", etc. for date\n  columns.\n\n  Note that if the clicked column is an aggregation, filtering by it will require a new stage. Therefore this drill\n  returns a possibly-updated `:query` and `:stage-number` along with a `:column` referencing that later stage.',
$CLJS.n(n8)?n8.H:null])),F8,!0],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return o8},Aua,$CLJS.zg([$CLJS.Ok,$CLJS.Ai,$CLJS.U,$CLJS.yj,$CLJS.ei,$CLJS.xi,$CLJS.Oi,$CLJS.Gj,$CLJS.di,$CLJS.vj,$CLJS.xj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.JK,$CLJS.pr,$CLJS.m6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.g6],null)],null),vua,rua,"metabase/lib/drill_thru/distribution.cljc",28,1,42,42,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[I8,C8,new $CLJS.h(null,
1,[$CLJS.io,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xy,$CLJS.yi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.distribution]\n          \n\n  Select a column and see a histogram of how many rows fall into an automatic set of bins/buckets.\n  - For dates, breaks out by month by default.\n  - For numeric values, by an auto-selected set of bins\n  - For strings, by each distinct value (which might be \x3d the number of rows)",
$CLJS.n(o8)?o8.H:null])),F8,!0],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return p8},Yua,$CLJS.zg([$CLJS.Ok,$CLJS.Ai,$CLJS.U,$CLJS.yj,$CLJS.ei,$CLJS.xi,$CLJS.Oi,$CLJS.Gj,$CLJS.di,$CLJS.vj,$CLJS.xj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.JK,$CLJS.pr,$CLJS.m6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.W6],null)],null),Uua,Bua,"metabase/lib/drill_thru/fk_filter.cljc",25,1,37,37,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[I8,C8,new $CLJS.h(null,
2,[$CLJS.io,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xy,H8,$CLJS.yi],null),$CLJS.Xr,D8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.fk-filter]\n          \n\n  When clicking on a foreign key value, filter this query by that column.\n\n  This has the same effect as the `\x3d` filter on a generic field (ie. not a key), but renders differently.\n\n  Contrast [[metabase.lib.drill-thru.object-details/object-detail-drill]], which shows the details of the foreign\n  object.",
$CLJS.n(p8)?p8.H:null])),F8,!1],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return q8},Sua,$CLJS.zg([$CLJS.Ok,$CLJS.Ai,$CLJS.U,$CLJS.yj,$CLJS.ei,$CLJS.xi,$CLJS.Oi,$CLJS.Gj,$CLJS.di,$CLJS.vj,$CLJS.xj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.JK,$CLJS.pr,$CLJS.m6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dr,$CLJS.a6,$CLJS.X6,$CLJS.M6],null)],null)],null),xua,Nua,"metabase/lib/drill_thru/object_details.cljc",
29,1,10,10,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[I8,C8,G8],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int context :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe\n           [:or\n            :metabase.lib.schema.drill-thru/drill-thru.pk\n            :metabase.lib.schema.drill-thru/drill-thru.zoom\n            :metabase.lib.schema.drill-thru/drill-thru.fk-details]]\n          \n\n  When clicking a foreign key or primary key value, drill through to the details for that specific object.\n\n  Contrast [[metabase.lib.drill-thru.fk-filter/fk-filter-drill]], which filters this query to only those rows with a\n  specific value for a FK column.",
$CLJS.n(q8)?q8.H:null])),F8,!1],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return s8},Tua,$CLJS.zg([$CLJS.Ok,$CLJS.Ai,$CLJS.U,$CLJS.yj,$CLJS.ei,$CLJS.xi,$CLJS.Oi,$CLJS.Gj,$CLJS.di,$CLJS.vj,$CLJS.xj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.JK,$CLJS.pr,$CLJS.m6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.k6],null)],null),kua,Dua,"metabase/lib/drill_thru/pivot.cljc",21,1,119,119,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[I8,C8,new $CLJS.h(null,
2,[$CLJS.io,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xy,E8,$CLJS.yi],null),$CLJS.Xr,G8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.pivot]\n          \n\n  Return all possible pivoting options on the given column and value.\n\n  See `:pivots` key, which holds a map `{t [breakouts...]}` where `t` is `:category`, `:location`, or `:time`.\n  If a key is missing, there are no breakouts of that kind.",
$CLJS.n(s8)?s8.H:null])),F8,!1],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return u8},uua,$CLJS.zg([$CLJS.Ok,$CLJS.Ai,$CLJS.U,$CLJS.yj,$CLJS.ei,$CLJS.xi,$CLJS.Oi,$CLJS.Gj,$CLJS.di,$CLJS.vj,$CLJS.xj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.JK,$CLJS.pr,$CLJS.m6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.N6],null)],null),Eua,$ua,"metabase/lib/drill_thru/quick_filter.cljc",28,1,93,93,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[I8,C8,new $CLJS.h(null,
2,[$CLJS.io,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xy,H8,$CLJS.yi],null),$CLJS.Xr,D8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.quick-filter]\n          \n\n  Filter the current query based on the value clicked.\n\n  The options vary depending on the type of the field:\n  - `:is-null` and `:not-null` for a `NULL` value;\n  - `:\x3d` and `:!\x3d` for everything else;\n  - plus `:\x3c` and `:\x3e` for numeric and date columns.\n\n  Note that this returns a single `::drill-thru` value with 1 or more `:operators`; these are rendered as a set of small\n  buttons in a single row of the drop-down.",
$CLJS.n(u8)?u8.H:null])),F8,!1],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return v8},Zua,$CLJS.zg([$CLJS.Ok,$CLJS.Ai,$CLJS.U,$CLJS.yj,$CLJS.ei,$CLJS.xi,$CLJS.Oi,$CLJS.Gj,$CLJS.di,$CLJS.vj,$CLJS.xj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.JK,$CLJS.pr,$CLJS.m6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.g7],null)],null),Kua,sua,"metabase/lib/drill_thru/sort.cljc",20,1,51,51,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[I8,C8,new $CLJS.h(null,
2,[$CLJS.io,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xy,H8,$CLJS.yi],null),$CLJS.Xr,D8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.sort]\n          \n\n  Sorting on a clicked column.",$CLJS.n(v8)?v8.H:null])),F8,!0],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return w8},Vua,$CLJS.zg([$CLJS.Ok,$CLJS.Ai,
$CLJS.U,$CLJS.yj,$CLJS.ei,$CLJS.xi,$CLJS.Oi,$CLJS.Gj,$CLJS.di,$CLJS.vj,$CLJS.xj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.JK,$CLJS.pr,$CLJS.m6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.s6],null)],null),Cua,Hua,"metabase/lib/drill_thru/summarize_column.cljc",32,1,31,31,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[I8,C8,new $CLJS.h(null,1,[$CLJS.io,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xy,$CLJS.yi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column]\n          \n\n  A set of possible aggregations that can summarize this column: distinct values, sum, average.\n  Separate from [[summarize-column-by-time-drill]] which breaks out a column over time.",
$CLJS.n(w8)?w8.H:null])),F8,!0],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return x8},Gua,$CLJS.zg([$CLJS.Ok,$CLJS.Ai,$CLJS.U,$CLJS.yj,$CLJS.ei,$CLJS.xi,$CLJS.Oi,$CLJS.Gj,$CLJS.di,$CLJS.vj,$CLJS.xj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.JK,$CLJS.pr,$CLJS.m6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.q6],null)],null),nua,Rua,"metabase/lib/drill_thru/summarize_column_by_time.cljc",40,1,40,40,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,
[I8,C8,new $CLJS.h(null,1,[$CLJS.io,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xy,$CLJS.yi],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time]\n          \n\n  A breakout summarizing a column over time.\n  Separate from single-value [[summarize-column-drill]] for sum, average, and distinct value count.",$CLJS.n(x8)?
x8.H:null])),F8,!0],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return k8},Jua,$CLJS.zg([$CLJS.Ok,$CLJS.Ai,$CLJS.U,$CLJS.yj,$CLJS.ei,$CLJS.xi,$CLJS.Oi,$CLJS.Gj,$CLJS.di,$CLJS.vj,$CLJS.xj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.JK,$CLJS.pr,$CLJS.m6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.e7],null)],null),Fua,oua,"metabase/lib/drill_thru/underlying_records.cljc",34,1,51,51,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[I8,C8,new $CLJS.h(null,
2,[$CLJS.io,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Xy,H8,E8,$CLJS.yi],null),$CLJS.Xr,D8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.underlying-records]\n          \n\n  When clicking on a particular broken-out group, offer a look at the details of all the rows that went into this\n  bucket. Eg. distribution of People by State, then click New York and see the table of all People filtered by\n  `STATE \x3d 'New York'`.\n\n  There is another quite different case: clicking the legend of a chart with multiple bars or lines broken out by\n  category. Then `column` is nil!",
$CLJS.n(k8)?k8.H:null])),F8,!1],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return B8},pua,$CLJS.zg([$CLJS.Ok,$CLJS.Ai,$CLJS.U,$CLJS.yj,$CLJS.ei,$CLJS.xi,$CLJS.Oi,$CLJS.Gj,$CLJS.di,$CLJS.vj,$CLJS.xj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.JK,$CLJS.pr,$CLJS.m6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.$6],null)],null),Wua,qua,"metabase/lib/drill_thru/zoom_in_timeseries.cljc",34,1,89,89,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[I8,C8,
new $CLJS.h(null,2,[$CLJS.io,new $CLJS.P(null,1,5,$CLJS.Q,[E8],null),$CLJS.Xr,D8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [dimensions], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries]\n          \n\n  Zooms in on some window, showing it in finer detail.\n\n  For example: The month of a year, days or weeks of a quarter, smaller lat/long regions, etc.\n\n  This is different from the `:drill-thru/zoom` type, which is for showing the details of a single object.",
$CLJS.n(B8)?B8.H:null])),F8,!1],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return A8},zua,$CLJS.zg([$CLJS.Ok,$CLJS.Ai,$CLJS.U,$CLJS.yj,$CLJS.ei,$CLJS.xi,$CLJS.Oi,$CLJS.Gj,$CLJS.di,$CLJS.vj,$CLJS.xj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.JK,$CLJS.pr,$CLJS.m6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.a7],null)],null),Lua,Pua,"metabase/lib/drill_thru/zoom_in_geographic.cljc",34,1,189,189,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[I8,
C8,new $CLJS.h(null,2,[$CLJS.io,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.yi],null),$CLJS.Xr,G8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.geographic]\n          \n\n  Return a `:drill-thru/zoom-in.geographic` drill if appropriate. See docstring\n  for [[metabase.lib.drill-thru.zoom-in-geographic]] for more information on what circumstances this is returned in\n  and what it means to apply this drill.",
$CLJS.n(A8)?A8.H:null])),F8,!0],null),new $CLJS.h(null,2,[$CLJS.Rr,new $CLJS.Cc(function(){return y8},mua,$CLJS.zg([$CLJS.Ok,$CLJS.Ai,$CLJS.U,$CLJS.yj,$CLJS.ei,$CLJS.xi,$CLJS.Oi,$CLJS.Gj,$CLJS.di,$CLJS.vj,$CLJS.xj],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nn,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sq,$CLJS.JK,$CLJS.pr,$CLJS.m6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.c7],null)],null),lua,wua,"metabase/lib/drill_thru/zoom_in_bins.cljc",31,1,86,86,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[I8,C8,new $CLJS.h(null,
2,[$CLJS.io,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xy,$CLJS.yi],null),$CLJS.Xr,D8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.binning]\n          \n\n  Return a drill thru that 'zooms in' on a breakout that uses `:binning` if applicable.\n  See [[metabase.lib.drill-thru.zoom-in-bins]] docstring for more information.",
$CLJS.n(y8)?y8.H:null])),F8,!0],null)],null);
$CLJS.bva=function(){function a(d,e,f){try{return $CLJS.Wf.g($CLJS.xf,$CLJS.Z0(d)?function(){var l=jua(f);return function u(t){return new $CLJS.ie(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var A=x,C=$CLJS.z(A),G=$CLJS.O(C),K=$CLJS.J.g(G,$CLJS.Rr),S=$CLJS.J.g(G,F8);if(x=$CLJS.y(function(X,T,da,oa,Ma,sb,Qa,Xa){return function Ta(Ua){return new $CLJS.ie(null,function(mb,dc,Kd,Hd){return function(){for(var gd=Ua;;)if(gd=$CLJS.y(gd)){if($CLJS.vd(gd)){var Rh=$CLJS.ic(gd),qz=$CLJS.D(Rh),Il=$CLJS.le(qz);
a:for(var Un=0;;)if(Un<qz){var zr=$CLJS.hd(Rh,Un);zr=Hd.j?Hd.j(d,e,zr):Hd.call(null,d,e,zr);$CLJS.n(zr)&&Il.add(zr);Un+=1}else{Rh=!0;break a}return Rh?$CLJS.oe($CLJS.qe(Il),Ta($CLJS.jc(gd))):$CLJS.oe($CLJS.qe(Il),null)}Il=$CLJS.z(gd);Il=Hd.j?Hd.j(d,e,Il):Hd.call(null,d,e,Il);if($CLJS.n(Il))return $CLJS.ae(Il,Ta($CLJS.Hc(gd)));gd=$CLJS.Hc(gd)}else return null}}(X,T,da,oa,Ma,sb,Qa,Xa),null,null)}}(v,C,G,K,S,A,x,l)($CLJS.n(function(){var X=S;return $CLJS.n(X)?l:X}())?l:new $CLJS.P(null,1,5,$CLJS.Q,[f],
null))))return $CLJS.bf.g(x,u($CLJS.Hc(v)));v=$CLJS.Hc(v)}else return null}},null,null)}(ava)}():null)}catch(l){var k=l;throw $CLJS.Th(["Error getting available drill thrus for query: ",$CLJS.p.h($CLJS.rU(k))].join(""),new $CLJS.h(null,3,[$CLJS.EE,d,$CLJS.DK,e,$CLJS.oM,f],null),k);}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();
$CLJS.cva=function(){function a(d,e){return b.j?b.j(d,-1,e):b.call(null,d,-1,e)}var b=null,c=function(){function d(f,k,l,m){var t=null;if(3<arguments.length){t=0;for(var u=Array(arguments.length-3);t<u.length;)u[t]=arguments[t+3],++t;t=new $CLJS.w(u,0,null)}return e.call(this,f,k,l,t)}function e(f,k,l,m){var t=$CLJS.Mz($CLJS.Bx);$CLJS.n($CLJS.Lz("metabase.lib.drill-thru",t))&&$CLJS.Kz("metabase.lib.drill-thru",t,$CLJS.sV("Applying drill thru: %s",$CLJS.H([$CLJS.tV(new $CLJS.h(null,4,[$CLJS.EE,f,$CLJS.DK,
k,tua,l,$CLJS.Kr,m],null))])),null);return $CLJS.Ne.M($CLJS.W5,f,k,l,m)}d.A=3;d.B=function(f){var k=$CLJS.z(f);f=$CLJS.B(f);var l=$CLJS.z(f);f=$CLJS.B(f);var m=$CLJS.z(f);f=$CLJS.Hc(f);return e(k,l,m,f)};d.l=e;return d}();b=function(d,e,f,k){switch(arguments.length){case 2:return a.call(this,d,e);default:var l=null;if(3<arguments.length){l=0;for(var m=Array(arguments.length-3);l<m.length;)m[l]=arguments[l+3],++l;l=new $CLJS.w(m,0,null)}return c.l(d,e,f,l)}throw Error("Invalid arity: "+arguments.length);
};b.A=3;b.B=c.B;b.g=a;b.l=c.l;return b}();