var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var AK,CK,EK,HK,KK;$CLJS.yK=function(a){return $CLJS.Hh($CLJS.q($CLJS.gB),a,$CLJS.mF)};$CLJS.zK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);AK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.BK=new $CLJS.M(null,"operators","operators",-2064102509);CK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);
$CLJS.DK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);EK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.FK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.GK=new $CLJS.M(null,"dimensions","dimensions",-254818097);HK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.IK=new $CLJS.M(null,"max-value","max-value",687805168);
$CLJS.JK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);KK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.LK=new $CLJS.M(null,"null","null",-180137709);$CLJS.MK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(CK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.uD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.cD],null)],null)],null));$CLJS.Y(KK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,CK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.yD],null)],null)],null));$CLJS.Y(EK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.iF],null),KK,$CLJS.pD],null));
$CLJS.Y(HK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.iF],null),CK,$CLJS.gK],null));
$CLJS.IF.g($CLJS.iF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.iF],null),CK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.gK,$CLJS.pD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ti,function(a){return $CLJS.rd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.XA(a)):null},$CLJS.ts,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,HK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,EK],null)],null)],null));$CLJS.oE($CLJS.iF,$CLJS.mF);$CLJS.JD.m(null,$CLJS.iF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.li,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.XD});$CLJS.vE($CLJS.qA,$CLJS.H([$CLJS.pD]));$CLJS.JD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.li,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.XD});
$CLJS.oE($CLJS.qA,$CLJS.mF);$CLJS.Y(AK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.uD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.pD],null)],null)],null));
$CLJS.IF.g($CLJS.YE,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.YE],null),AK,$CLJS.oj],null));$CLJS.JD.m(null,$CLJS.YE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.li,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.XD});$CLJS.oE($CLJS.YE,$CLJS.mF);$CLJS.vE($CLJS.GE,$CLJS.H([$CLJS.Zr,$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.MJ],null)],null)]));$CLJS.oE($CLJS.GE,$CLJS.mF);
$CLJS.vE($CLJS.dF,$CLJS.H([$CLJS.Zr,$CLJS.XD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KJ],null)],null)]));$CLJS.oE($CLJS.dF,$CLJS.mF);
$CLJS.Y($CLJS.mF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.LD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wi,new $CLJS.h(null,1,[$CLJS.Es,function(){return["Valid reference, must be one of these clauses: ",$CLJS.hs(", ",$CLJS.Cz($CLJS.Dd,$CLJS.rk.g($CLJS.q($CLJS.gB),$CLJS.mF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.yK(a)}],null)],null));