var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.cache.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Qoa,WZ,Roa,YZ,ZZ,$Z,w_,Uoa,Toa,Voa,Soa,C_,n_,E_,i_,Woa,p_;Qoa=function(a){return $CLJS.y(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};WZ=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Cd($CLJS.Yfa,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
Roa=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.vA,!0],null)),c=$CLJS.J.g(b,$CLJS.Xfa),d=$CLJS.J.g(b,$CLJS.vA);return $CLJS.y(a)?(b=$CLJS.uU(function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);x=WZ(x,$CLJS.Ra(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae(WZ(u,
$CLJS.Ra(d)),k($CLJS.Hc(l)))}return null}},null,null)}(Qoa($CLJS.aA(a)))}()),$CLJS.n(c)?$CLJS.uU($CLJS.We(c,b)):b):null};$CLJS.XZ=function(a){return $CLJS.dO.h($CLJS.z($CLJS.qK.h(a)))};YZ=function(a,b){if(null!=a&&null!=a.Ag)a=a.Ag(a,b);else{var c=YZ[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=YZ._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.table",a);}return a};
ZZ=function(a,b){if(null!=a&&null!=a.rg)a=a.rg(a,b);else{var c=ZZ[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=ZZ._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.card",a);}return a};$Z=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=$Z[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=$Z._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.fields",a);}return a};
$CLJS.a_=function(a){return $CLJS.sY(a)?a:$CLJS.gD.h(a)};$CLJS.b_=function(a,b){return YZ($CLJS.a_(a),b)};$CLJS.c_=function(a,b){return $Z($CLJS.a_(a),b)};$CLJS.d_=function(a,b){return ZZ($CLJS.a_(a),b)};
$CLJS.f_=function(a,b,c,d,e){var f=$CLJS.O(e);e=$CLJS.J.j(f,$CLJS.bZ,$CLJS.e_);f=$CLJS.J.g(f,$CLJS.aZ);if($CLJS.n($CLJS.n(f)?f:$CLJS.Va(b)||$CLJS.sd(b))&&($CLJS.n(b.__mbcache)||(b.__mbcache=e.o?e.o():e.call(null)),b=b.__mbcache,$CLJS.n(b))){f=e.j?e.j(b,a,c):e.call(null,b,a,c);if($CLJS.n(f))return f;d=d.h?d.h(c):d.call(null,c);e.v?e.v(b,a,c,d):e.call(null,b,a,c,d);return d}return d.h?d.h(c):d.call(null,c)};
$CLJS.g_=function(a,b,c){return $CLJS.f_(a,b,b,c,new $CLJS.h(null,2,[$CLJS.bZ,$CLJS.e_,$CLJS.aZ,!1],null))};
w_=function(a,b,c){var d=$CLJS.h_.j(a,b,c);return $CLJS.ok.l($CLJS.H([$CLJS.Bl(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,$CLJS.kD,$CLJS.lj],null)),function(){var e=$CLJS.NV.h(c);return $CLJS.n(e)?new $CLJS.h(null,2,[$CLJS.kD,e,i_,!0],null):null}(),function(){var e=$CLJS.j_.v(a,b,c,$CLJS.k_);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.l_,e],null):null}(),function(){var e=$CLJS.zz($CLJS.li,$CLJS.tA)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.li,e],null):null}(),function(){var e=$CLJS.ZJ.h(d);return $CLJS.n(e)?
(e=$CLJS.Ad(e)?$CLJS.b_(a,e):"string"===typeof e?$CLJS.d_(a,$CLJS.EV(e)):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.m_.j(a,b,e)],null):null):null}(),function(){var e=$CLJS.LJ.h(d);return $CLJS.n(e)?new $CLJS.h(null,6,[n_,$CLJS.E.g(e,$CLJS.uY),$CLJS.o_,$CLJS.E.g(e,$CLJS.FY),p_,$CLJS.E.g(e,$CLJS.CY),$CLJS.q_,$CLJS.E.g(e,$CLJS.EY),Soa,$CLJS.E.g(e,$CLJS.jK),Toa,$CLJS.E.g(e,$CLJS.aK)],null):null}(),function(){var e=$CLJS.KY.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.r_,e],null)}(),function(){var e=
$CLJS.zz($CLJS.HV,$CLJS.oF)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.s_,$CLJS.Cd($CLJS.oG,e)&&!$CLJS.Cd($CLJS.nG,e)],null):null}(),$CLJS.Bl(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.t_,$CLJS.u_,$CLJS.v_],null))]))};$CLJS.e_=function e_(a){switch(arguments.length){case 0:return e_.o();case 3:return e_.j(arguments[0],arguments[1],arguments[2]);case 4:return e_.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.e_.o=function(){return $CLJS.Se($CLJS.N)};$CLJS.e_.j=function(a,b){return $CLJS.J.g($CLJS.q(a),b)};$CLJS.e_.v=function(a,b,c,d){return $CLJS.zh.v(a,$CLJS.R,b,d)};$CLJS.e_.A=4;$CLJS.x_=new $CLJS.M(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.v_=new $CLJS.M(null,"filter-positions","filter-positions",378749375);$CLJS.y_=new $CLJS.M(null,"direction","direction",-633359395);$CLJS.q_=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);
$CLJS.t_=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.z_=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.r_=new $CLJS.M(null,"selected","selected",574897764);Uoa=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);Toa=new $CLJS.M(null,"is-breakout","is-breakout",-1878069983);
Voa=new $CLJS.r("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.A_=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);$CLJS.B_=new $CLJS.M("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);Soa=new $CLJS.M(null,"is-aggregation","is-aggregation",872502629);
C_=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.D_=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);n_=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);E_=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);i_=new $CLJS.M(null,"named?","named?",-213401078);$CLJS.F_=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);
$CLJS.o_=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.s_=new $CLJS.M(null,"is-temporal-extraction","is-temporal-extraction",-1029608872);$CLJS.G_=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.u_=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);Woa=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.l_=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);
$CLJS.H_=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.k_=new $CLJS.M(null,"long","long",-171452093);$CLJS.I_=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.J_=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);p_=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.K_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Li,$CLJS.gB],null),$CLJS.Li,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.L_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Li,$CLJS.gB],null),$CLJS.Li,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.j_=function(){function a(f,k,l,m){var t=$CLJS.zz($CLJS.kD,$CLJS.oD)($CLJS.DD(l));if($CLJS.n(t))return t;try{return $CLJS.K_.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.Th($CLJS.fD("Error calculating display name for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([l])),$CLJS.rU(k)])),new $CLJS.h(null,2,[$CLJS.EE,f,$CLJS.xD,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.Oh):e.call(null,f,k,l,$CLJS.Oh)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.M_=function(){function a(d,e,f){var k=$CLJS.U.h($CLJS.DD(f));if($CLJS.n(k))return k;try{return $CLJS.L_.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.Th($CLJS.fD("Error calculating column name for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([f])),$CLJS.rU(k)])),new $CLJS.h(null,3,[$CLJS.xD,f,$CLJS.EE,d,$CLJS.DK,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.K_.m(null,$CLJS.Oh,function(a,b,c){a=$CLJS.Mz($CLJS.Ex);$CLJS.n($CLJS.Lz("metabase.lib.metadata.calculation",a))&&$CLJS.Kz("metabase.lib.metadata.calculation",a,$CLJS.sV("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.H([$CLJS.xh.l($CLJS.H([c])),Voa,$CLJS.XA(c)])),null);return $CLJS.ud(c)&&$CLJS.z(c)instanceof $CLJS.M?$CLJS.Xg($CLJS.z(c)):$CLJS.xh.l($CLJS.H([c]))});
$CLJS.L_.m(null,$CLJS.Oh,function(a,b,c){return Roa($CLJS.Gz($CLJS.j_.j(a,b,c),/[\(\)]/,""))});$CLJS.N_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Li,$CLJS.gB],null),$CLJS.Li,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.Xoa=function(){function a(d,e,f){return $CLJS.N_.j(d,e,$CLJS.mh.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.O_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Li,$CLJS.gB],null),$CLJS.Li,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.P_=function(){function a(d,e,f){var k=$CLJS.DD(f);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.oF),m=$CLJS.li.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Cd($CLJS.oG,l):l)?$CLJS.Ui:null;if($CLJS.n(l))return l;k=$CLJS.tA.h(k);if($CLJS.n(k))return k;d=$CLJS.O_.j(d,e,f);return $CLJS.Dz(d,$CLJS.Yi)?d:$CLJS.Yi}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.O_.m(null,$CLJS.Oh,function(a,b,c){return $CLJS.KD(c)});$CLJS.O_.m(null,$CLJS.aE,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.P_.j(a,b,c)});$CLJS.O_.m(null,$CLJS.XH,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.KD(c):$CLJS.P_.j(a,b,d)});
$CLJS.Q_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Li,$CLJS.gB],null),$CLJS.Li,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.Q_.m(null,$CLJS.Oh,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.JJ,$CLJS.tA,$CLJS.P_.j(a,b,c),$CLJS.U,$CLJS.M_.j(a,b,c),$CLJS.kD,$CLJS.j_.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.Th($CLJS.fD("Error calculating metadata for {0}: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([$CLJS.XA(c)])),$CLJS.rU(d)])),new $CLJS.h(null,3,[$CLJS.EE,a,$CLJS.DK,b,$CLJS.xD,c],null),d);}throw e;}});
$CLJS.h_=function(){function a(e,f,k){return $CLJS.Q_.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.R_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Li,$CLJS.gB],null),$CLJS.Li,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();
$CLJS.Y(C_,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.oj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.l_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.oj],null),new $CLJS.P(null,3,5,$CLJS.Q,[i_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,C_],null)],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[n_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.o_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[p_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.q_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[E_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Woa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Uoa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),$CLJS.oj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TE,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.oj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.oj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.I_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.oj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.G_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.r_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.y_,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ar,$CLJS.fF,$CLJS.ZE],null)],null)],null));
$CLJS.m_=function(){function a(d,e,f){return $CLJS.g_($CLJS.mh.g("display-info",["stage-",$CLJS.p.h(e)].join("")),f,function(k){try{return $CLJS.R_.j(d,e,k)}catch(m){if(m instanceof Error){var l=m;throw $CLJS.Th($CLJS.fD("Error calculating display info for {0}: {1}",$CLJS.H([$CLJS.XA(k),$CLJS.rU(l)])),new $CLJS.h(null,3,[$CLJS.EE,d,$CLJS.DK,e,$CLJS.xD,k],null),l);}throw m;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.R_.m(null,$CLJS.Oh,function(a,b,c){return w_(a,b,c)});$CLJS.R_.m(null,$CLJS.RJ,function(a,b,c){return $CLJS.ok.l($CLJS.H([w_(a,b,c),new $CLJS.h(null,1,[E_,$CLJS.E.g($CLJS.XZ(a),$CLJS.Di.h(c))],null)]))});
$CLJS.T_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Li,$CLJS.gB],null),$CLJS.Li,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();$CLJS.T_.m(null,$CLJS.wA,function(){return $CLJS.xf});
$CLJS.U_=function(){function a(f,k,l,m){m=$CLJS.ok.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.F_,$CLJS.GV()],null),m]));return $CLJS.T_.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.DV(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.V_=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Li,$CLJS.gB],null),$CLJS.Li,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.XA(l)},e,a,b,c,d)}();$CLJS.V_.m(null,$CLJS.wA,function(){return $CLJS.xf});
$CLJS.V_.m(null,$CLJS.Oh,function(a,b,c,d){return $CLJS.T_.v(a,b,c,d)});
$CLJS.W_=function(){function a(f,k,l,m){m=$CLJS.ok.l($CLJS.H([$CLJS.ok.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.F_,$CLJS.GV()],null),new $CLJS.h(null,4,[$CLJS.D_,!0,$CLJS.J_,!0,$CLJS.z_,!0,$CLJS.H_,!0],null)])),m]));return $CLJS.V_.v(f,k,l,m)}function b(f,k,l){return $CLJS.n(function(){var m=$CLJS.sd(l);if(m){m=$CLJS.Vs.h(l);var t=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.$U,null,$CLJS.TU,null],null),null);return t.h?t.h(m):t.call(null,m)}return m}())?$CLJS.g_($CLJS.mh.h([$CLJS.p.h(k),"__visible-columns-no-opts"].join("")),
f,function(){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}):e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.DV(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
module.exports={display_name:$CLJS.j_};