var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var N3,P3,R3,S3,T3,U3,V3,Era;N3=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=N3[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=N3._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.metric",a);}return a};$CLJS.O3=function(a,b){return N3($CLJS.a_(a),b)};
P3=function(a,b,c){var d=$CLJS.DV(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.YE);d=$CLJS.rG($CLJS.Wj.j($CLJS.Tg([c]),$CLJS.wD,$CLJS.dd),d);if(!$CLJS.n(d))throw $CLJS.Uh($CLJS.fD("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.sr,c,$CLJS.EE,a,$CLJS.DK,b],null));return d};$CLJS.Q3=function(a){return $CLJS.E1($CLJS.uF,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};R3=new $CLJS.M("metabase.lib.aggregation","no-semantic-type","metabase.lib.aggregation/no-semantic-type",-789440092);
S3=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);T3=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);U3=new $CLJS.M("metabase.lib.aggregation","quantity-aggregation","metabase.lib.aggregation/quantity-aggregation",411632918);V3=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);
Era=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.N_.m(null,$CLJS.YE,function(a,b){var c=$CLJS.Be($CLJS.YE.h($CLJS.DV(a,b)));return $CLJS.n(c)?$CLJS.H1($CLJS.HD("and"),function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u);v=$CLJS.j_.v(a,b,v,$CLJS.k_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.j_.v(a,
b,t,$CLJS.k_),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):null});$CLJS.Q_.m(null,$CLJS.YE,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.tA);e=$CLJS.J.g(e,$CLJS.li);c=$CLJS.I(c,2,null);c=P3(a,b,c);return $CLJS.ok.l($CLJS.H([$CLJS.h_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.LJ,$CLJS.jK,$CLJS.B0,$CLJS.wD.h($CLJS.dd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.tA,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.li,e],null):null]))});
$CLJS.K_.m(null,$CLJS.YE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.j_.v(a,b,P3(a,b,c),d)});$CLJS.oE(T3,V3);
for(var W3=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.jF,$CLJS.rF],null)),X3=null,Y3=0,Z3=0;;)if(Z3<Y3){var Fra=X3.X(null,Z3);$CLJS.oE(Fra,T3);Z3+=1}else{var $3=$CLJS.y(W3);if($3){var a4=$3;if($CLJS.vd(a4)){var b4=$CLJS.ic(a4),Gra=$CLJS.jc(a4),Hra=b4,Ira=$CLJS.D(b4);W3=Gra;X3=Hra;Y3=Ira}else{var Jra=$CLJS.z(a4);$CLJS.oE(Jra,T3);W3=$CLJS.B(a4);X3=null;Y3=0}Z3=0}else break}
$CLJS.K_.m(null,T3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.j_.v(a,b,c,d),e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.fD("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.fD("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.S:null,e){case "count":return $CLJS.HD("Count");case "cum-count":return $CLJS.HD("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.L_.m(null,T3,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.S:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";case "count-where":return"count_where";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.Q_.m(null,U3,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.kH($CLJS.Q_,V3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.lj,$CLJS.SB)});
$CLJS.oE(U3,V3);$CLJS.oE(T3,U3);$CLJS.oE($CLJS.NE,U3);$CLJS.K_.m(null,$CLJS.RE,function(){return $CLJS.HD("Case")});$CLJS.L_.m(null,$CLJS.RE,function(){return"case"});$CLJS.oE(S3,V3);
for(var c4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.cF,$CLJS.EF,$CLJS.NE,$CLJS.uj,$CLJS.vF,$CLJS.oi,$CLJS.xF,$CLJS.uF,$CLJS.OE],null)),d4=null,e4=0,f4=0;;)if(f4<e4){var Kra=d4.X(null,f4);$CLJS.oE(Kra,S3);f4+=1}else{var g4=$CLJS.y(c4);if(g4){var h4=g4;if($CLJS.vd(h4)){var i4=$CLJS.ic(h4),Lra=$CLJS.jc(h4),Mra=i4,Nra=$CLJS.D(i4);c4=Lra;d4=Mra;e4=Nra}else{var Ora=$CLJS.z(h4);$CLJS.oE(Ora,S3);c4=$CLJS.B(h4);d4=null;e4=0}f4=0}else break}
$CLJS.L_.m(null,S3,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.S:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.K_.m(null,S3,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.j_.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "avg":return $CLJS.fD("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.fD("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.fD("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.fD("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.fD("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.fD("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.fD("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.fD("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.fD("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.K_.m(null,$CLJS.UE,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.fD("{0}th percentile of {1}",$CLJS.H([c,$CLJS.j_.v(a,b,e,d)]))});$CLJS.L_.m(null,$CLJS.UE,function(){return"percentile"});
$CLJS.oE(R3,V3);for(var j4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UE,$CLJS.OE],null)),k4=null,l4=0,m4=0;;)if(m4<l4){var Pra=k4.X(null,m4);$CLJS.oE(Pra,R3);m4+=1}else{var n4=$CLJS.y(j4);if(n4){var o4=n4;if($CLJS.vd(o4)){var p4=$CLJS.ic(o4),Qra=$CLJS.jc(o4),Rra=p4,Sra=$CLJS.D(p4);j4=Qra;k4=Rra;l4=Sra}else{var Tra=$CLJS.z(o4);$CLJS.oE(Tra,R3);j4=$CLJS.B(o4);k4=null;l4=0}m4=0}else break}
$CLJS.Q_.m(null,R3,function(a,b,c){var d=$CLJS.Pj.g,e=$CLJS.kH($CLJS.Q_,V3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.Pj,a,$CLJS.lj)});$CLJS.K_.m(null,$CLJS.CF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.fD("Sum of {0} matching condition",$CLJS.H([$CLJS.j_.v(a,b,e,d)]))});
$CLJS.L_.m(null,$CLJS.CF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.M_.j(a,b,d))].join("")});$CLJS.oE($CLJS.CF,V3);$CLJS.K_.m(null,$CLJS.pF,function(){return $CLJS.HD("Share of rows matching condition")});$CLJS.L_.m(null,$CLJS.pF,function(){return"share"});$CLJS.Q_.m(null,$CLJS.pF,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.kH($CLJS.Q_,V3);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.lj,$CLJS.jC)});
$CLJS.oE($CLJS.pF,V3);$CLJS.K_.m(null,$CLJS.rF,function(){return $CLJS.HD("Count of rows matching condition")});$CLJS.Q_.m(null,V3,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.ok.l;d=$CLJS.n(d)?$CLJS.Bl($CLJS.h_.j(a,b,d),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$J,$CLJS.lj],null)):null;var f=$CLJS.kH($CLJS.Q_,$CLJS.Oh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.ok,$CLJS.H([d,a]))});
$CLJS.Ura=function(){function a(d){return $CLJS.E1($CLJS.Rw,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.E1($CLJS.Rw,$CLJS.xf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.mV.m(null,$CLJS.YE,function(a){return a});
$CLJS.q4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.XA(f),$CLJS.PJ))f=$CLJS.oV(f);else return $CLJS.J1(d,e,$CLJS.YE,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.r4=function(){function a(d,e){return $CLJS.Be($CLJS.YE.h($CLJS.DV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.s4=function(){function a(d,e){var f=$CLJS.Be($CLJS.YE.h($CLJS.DV(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.xf,$CLJS.cf.h(function(k){var l=$CLJS.h_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.tA.h(l);return m.call($CLJS.R,$CLJS.D1(l,$CLJS.li,$CLJS.n(t)?t:$CLJS.Yi),$CLJS.LJ,$CLJS.jK,$CLJS.H([$CLJS.B0,$CLJS.wD.h($CLJS.dd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.K_.m(null,$CLJS.QF,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.MF);return $CLJS.kD.h(a.o?a.o():a.call(null))});$CLJS.R_.m(null,$CLJS.QF,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.bF);b=$CLJS.J.g(d,$CLJS.MF);c=$CLJS.J.g(d,$CLJS.LF);d=$CLJS.J.g(d,$CLJS.KY);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.I_,$CLJS.$z(a),$CLJS.H([$CLJS.G_,c]));return null!=d?$CLJS.R.j(a,$CLJS.r_,d):a});
$CLJS.Vra=function(){function a(d,e){var f=function(){var m=$CLJS.dz.h($CLJS.X0(d));return $CLJS.n(m)?m:$CLJS.Sg}(),k=$CLJS.DV(d,e),l=$CLJS.W_.j(d,e,k);return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.Wj.j($CLJS.ef(function(m){m=$CLJS.OF.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.tl(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.LF),u=$CLJS.J.g(m,$CLJS.JF);if($CLJS.Ra(t))return m;if($CLJS.E.g(u,$CLJS.ur))return $CLJS.R.j(m,$CLJS.AV,l);t=$CLJS.Be($CLJS.w0(function(v){return $CLJS.AZ(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.AV,t):null}),$CLJS.cf.h(function(m){return $CLJS.R.j(m,$CLJS.Vs,$CLJS.QF)})),$CLJS.RF))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Wra=function(){function a(d,e){return $CLJS.nV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bF.h(d),$CLJS.N,$CLJS.pV.h(e)],null))}function b(d){if($CLJS.Ra($CLJS.LF.h(d)))return $CLJS.nV(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bF.h(d),$CLJS.N],null));var e=$CLJS.bF.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.Uh(e,new $CLJS.h(null,1,[Era,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();