var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.types.js");require("./metabase.util.malli.js");
'use strict';var y0,wpa,z0,A0;$CLJS.w0=function(a,b){return $CLJS.cc($CLJS.cb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.bc(c,d):c},$CLJS.ac($CLJS.xf),b))};y0=function(a){return $CLJS.n(x0)?["(",$CLJS.p.h(a),")"].join(""):a};wpa=function(a,b){var c=$CLJS.Zj.v(a,$CLJS.YO,$CLJS.QC($CLJS.Yd,$CLJS.xf),b);return $CLJS.ud($CLJS.QD.h(a))?$CLJS.Zj.v(c,$CLJS.QD,$CLJS.Yd,$CLJS.nV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,$CLJS.N,$CLJS.wV(b)],null))):c};
z0=function(a,b,c){var d=$CLJS.wV(c);return $CLJS.R.l($CLJS.h_.j(a,b,c),$CLJS.LJ,$CLJS.CY,$CLJS.H([$CLJS.U,d,$CLJS.kD,d]))};A0=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);$CLJS.B0=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var C0=function(){function a(d,e,f){var k=$CLJS.DV(d,e);k=$CLJS.rG($CLJS.Wj.g($CLJS.Tg([f]),$CLJS.wV),$CLJS.YO.h(k));if($CLJS.n(k))return k;throw $CLJS.Uh($CLJS.fD("No expression named {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.YQ,f,$CLJS.EE,d,$CLJS.DK,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.O_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=C0.j(a,b,c);return $CLJS.P_.j(a,b,c)});$CLJS.Q_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Vs,$CLJS.JJ,$CLJS.B0,$CLJS.wD.h(d),$CLJS.U,e,$CLJS.oD,e,$CLJS.kD,$CLJS.j_.j(a,b,c),$CLJS.tA,$CLJS.P_.j(a,b,c),$CLJS.LJ,$CLJS.CY],null)});$CLJS.K_.m(null,$CLJS.lA,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.K_.m(null,$CLJS.jA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.K_.m(null,$CLJS.kA,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.K_.m(null,$CLJS.zA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.K_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.L_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var x0=!1,xpa=new $CLJS.h(null,4,[$CLJS.Iq,"+",$CLJS.Zr,"-",$CLJS.Jq,"×",$CLJS.DF,"÷"],null),D0=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Iq,$CLJS.Zr,$CLJS.DF,$CLJS.Jq],null)),E0=null,F0=0,G0=0;;)if(G0<F0){var ypa=E0.X(null,G0);$CLJS.oE(ypa,A0);G0+=1}else{var H0=$CLJS.y(D0);if(H0){var I0=H0;if($CLJS.vd(I0)){var J0=$CLJS.ic(I0),zpa=$CLJS.jc(I0),Apa=J0,Bpa=$CLJS.D(J0);D0=zpa;E0=Apa;F0=Bpa}else{var Cpa=$CLJS.z(I0);$CLJS.oE(Cpa,A0);D0=$CLJS.B(I0);E0=null;F0=0}G0=0}else break}
$CLJS.K_.m(null,A0,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);$CLJS.z(d);d=$CLJS.B(d);c=$CLJS.J.g(xpa,c);a:{var e=x0;x0=!0;try{var f=$CLJS.hs([" ",$CLJS.Xg(c)," "].join(""),$CLJS.cf.g($CLJS.Xs($CLJS.j_,a,b),d));break a}finally{x0=e}f=void 0}return y0(f)});$CLJS.L_.m(null,A0,function(){return"expression"});
$CLJS.O_.m(null,$CLJS.yG,function(a,b,c){c=$CLJS.y(c);$CLJS.z(c);c=$CLJS.B(c);$CLJS.z(c);var d=$CLJS.B(c);return $CLJS.Md($CLJS.qE,function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v);x=$CLJS.P_.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae($CLJS.P_.j(a,
b,u),k($CLJS.Hc(l)))}return null}},null,null)}(d)}())});$CLJS.K_.m(null,$CLJS.bB,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.j_.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.q0.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=$CLJS.yz(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=y0(f);return[a," ",d.call(b,f)].join("")});
$CLJS.L_.m(null,$CLJS.bB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.M_.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.q0.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=$CLJS.yz(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.K_.m(null,$CLJS.lF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.j_.v(a,b,e,d)});$CLJS.L_.m(null,$CLJS.lF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.M_.j(a,b,d)});
$CLJS.Dpa=function(){function a(d,e,f,k){return $CLJS.OV.l(d,$CLJS.n(e)?e:-1,wpa,$CLJS.H([$CLJS.xV($CLJS.pV.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Epa=function(){function a(d,e){var f=$CLJS.Be($CLJS.YO.h($CLJS.DV(d,e)));return null==f?null:$CLJS.Yj.g($CLJS.Xs(z0,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.K0=function(){function a(d,e){return $CLJS.Be($CLJS.YO.h($CLJS.DV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.mV.m(null,$CLJS.qA,function(a){return a});
$CLJS.Fpa=function(){function a(d,e,f){var k=$CLJS.Wf.g($CLJS.N,$CLJS.Al(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wV(t),m],null)},$CLJS.K0.g(d,e))),l=$CLJS.DV(d,e);d=$CLJS.W_.j(d,e,l);return $CLJS.Be($CLJS.w0(function(m){return $CLJS.Ra(f)||$CLJS.Vj.g($CLJS.LJ.h(m),$CLJS.CY)||$CLJS.J.g(k,$CLJS.U.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Gpa=function(){function a(d,e,f){return $CLJS.oV(z0(d,e,C0.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.L0=$CLJS.lE.h($CLJS.WD);