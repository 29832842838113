import { t } from "ttag";

import { color } from "metabase/lib/colors";
import { DEFAULT_CELL_PADDING } from "metabase/visualizations/visualizations/PivotTable/constants";
import type { WidgetSetting } from "metabase-types/api/widgets/widget";

type Alignment = "flex-start" | "center" | "flex-end";

export type PivotTableSettingValues = {
  "pivot_table.cell_font_size": number;
  "pivot_table.cell_font_italic": boolean;
  "pivot_table.cell_font_bold": boolean;
  "pivot_table.cell_auto_height": boolean;
  "pivot_table.cell_height": number;
  "pivot_table.cell_horizontal_alignment": Alignment;
  "pivot_table.cell_vetrical_alignment": Alignment;
  "pivot_table.cell_auto_indent": boolean;
  "pivot_table.cell_indent_left": number;
  "pivot_table.cell_indent_vertical": number;
  "pivot_table.cell_text_color": string;
  "pivot_table.cell_odd_background_color": string;
  "pivot_table.cell_even_background_color": string;
  "pivot_table.left_header_background_color": string;
  "pivot_table.left_header_text_color": string;
  "pivot_table.cell_background_color_hover": string;
  "pivot_table.toggle_clicked_row_higlight": boolean;
  "pivot_table.title_font_size": number;
  "pivot_table.title_font_italic": boolean;
  "pivot_table.title_font_bold": boolean;
  "pivot_table.title_auto_height": boolean;
  "pivot_table.title_height": number;
  "pivot_table.header_horizontal_alignment": Alignment;
  "pivot_table.title_vertical_alignment": Alignment;
  "pivot_table.title_auto_indent": boolean;
  "pivot_table.title_indent_left": number;
  "pivot_table.titile_indent_vertical": number;
  "pivot_table.row_clicked_color": string;
  "pivot_table.header_text_color": string;
  "pivot_table.header_background_color": string;
  "pivot_table.header_background_color_hover": string;
  "pivot_table.header_transparent": boolean;
  "pivot_table.body_transparent": boolean;
  "pivot_table.pin_mode": boolean;
  "pivot_table.pinned_columns_count": number;
  "pivot_table.pinned_rows_count": number;
  "pivot.show_row_totals": boolean;
  "pivot.show_column_totals": boolean;
  "pivot_table.grid_color": string;
  "pivot_table.hierarchy": boolean;
  "pivot_table.method_of_request": boolean;
};

export type PivotTableSettingsKeys = keyof PivotTableSettingValues;

export type PivotTableSettings = Record<PivotTableSettingsKeys, WidgetSetting>;

export const PIVOT_TABLE_SETTINGS: PivotTableSettings = {
  "pivot_table.cell_font_size": {
    section: t`Cells`,
    title: t`Cell font size`,
    widget: "number",
    default: 12,
  },
  "pivot_table.cell_font_italic": {
    section: t`Cells`,
    title: t`Cell font italic`,
    widget: "toggle",
    getDefault: (series, vizSettings) =>
      !!vizSettings["pivot_table.cell_font_italic"],
  },
  "pivot_table.cell_font_bold": {
    section: t`Cells`,
    title: t`Cell font bold`,
    widget: "toggle",
    getDefault: (series, vizSettings) =>
      !!vizSettings["pivot_table.cell_font_bold"],
  },

  "pivot_table.cell_auto_height": {
    section: t`Cells`,
    title: t`Cell auto height`,
    widget: "toggle",
    default: true,
  },
  "pivot_table.cell_height": {
    section: t`Cells`,
    title: t`Cell height`,
    widget: "number",
    getProps: (series, vizSettings) =>
      vizSettings["pivot_table.cell_height"] <
      vizSettings["pivot_table.cell_font_size"]
        ? vizSettings["pivot_table.cell_font_size"]
        : vizSettings["pivot_table.cell_height"],
    getDefault: (series, vizSettings) =>
      vizSettings["pivot_table.cell_font_size"] + DEFAULT_CELL_PADDING * 2,
    isValid: (series, vizSettings) =>
      vizSettings["pivot_table.cell_height"] >
        vizSettings["pivot_table.cell_font_size"] &&
      vizSettings["pivot_table.cell_height"] <= 1000,
    readDependencies: [
      "pivot_table.cell_font_size, pivot_table.cell_auto_height",
    ],
    getHidden: (series, vizSettings) =>
      vizSettings["pivot_table.cell_auto_height"],
  },
  "pivot_table.cell_horizontal_alignment": {
    section: t`Cells`,
    title: t`Cell text horizontal alignment`,
    widget: "select",
    props: {
      options: [
        { name: t`Left`, value: "flex-start" },
        { name: t`Center`, value: "center" },
        { name: t`Right`, value: "flex-end" },
      ],
    },
    default: "flex-start",
    getHidden: (series, vizSettings) => vizSettings["pivot_table.hierarchy"],
  },
  "pivot_table.cell_vetrical_alignment": {
    section: t`Cells`,
    title: t`Cell text vertical alignment`,
    widget: "select",
    props: {
      options: [
        { name: t`Top`, value: "flex-start" },
        { name: t`Center`, value: "center" },
        { name: t`Bottom`, value: "flex-end" },
      ],
    },
    default: "center",
  },
  "pivot_table.cell_auto_indent": {
    section: t`Cells`,
    title: t`Cell auto indent`,
    widget: "toggle",
    default: true,
  },
  "pivot_table.cell_indent_left": {
    section: t`Cells`,
    title: t`Cell indent horizontal`,
    widget: "number",
    getProps: (series, vizSettings) =>
      vizSettings["pivot_table.cell_indent_left"] < 0
        ? 0
        : vizSettings["pivot_table.cell_indent_left"],
    default: DEFAULT_CELL_PADDING,
    isValid: (series, vizSettings) =>
      vizSettings["pivot_table.cell_indent_left"] >= 0,
    readDependencies: ["pivot_table.cell_auto_indent, table.cell_indent_left"],
    getHidden: (series, vizSettings) =>
      vizSettings["pivot_table.cell_auto_indent"],
  },
  "pivot_table.cell_indent_vertical": {
    section: t`Cells`,
    title: t`Cell indent vertical`,
    widget: "number",
    default: DEFAULT_CELL_PADDING,
    getProps: (series, vizSettings) => {
      if (vizSettings["pivot_table.cell_indent_vertical"] < 0) {
        return 0;
      }
      if (!vizSettings["pivot_table.cell_auto_height"]) {
        const newHeight =
          vizSettings["pivot_table.cell_indent_vertical"] +
          vizSettings["pivot_table.cell_font_size"];
        if (vizSettings["pivot_table.cell_height"] < newHeight) {
          return (
            vizSettings["pivot_table.cell_height"] -
            vizSettings["pivot_table.cell_font_size"]
          );
        }
      }
      return vizSettings["pivot_table.cell_indent_vertical"];
    },
    isValid: (series, vizSettings) => {
      if (vizSettings["pivot_table.cell_indent_vertical"] < 0) {
        return false;
      }
      if (!vizSettings["pivot_table.cell_auto_height"]) {
        return (
          vizSettings["pivot_table.cell_height"] >=
          vizSettings["pivot_table.cell_indent_vertical"] +
            vizSettings["pivot_table.cell_font_size"]
        );
      }
      return true;
    },
    readDependencies: [
      "pivot_table.cell_auto_indent",
      "pivot_table.cell_height",
      "pivot_table.cell_auto_height",
    ],
    getHidden: (series, vizSettings) =>
      vizSettings["pivot_table.cell_auto_indent"],
  },

  "pivot_table.cell_text_color": {
    section: t`Colors`,
    title: t`Cell text color`,
    widget: "color",
    getDefault: () => color("text-dark"),
  },
  "pivot_table.cell_odd_background_color": {
    section: t`Colors`,
    title: t`Odd rows background color`,
    widget: "color",
    getDefault: () => color("white"),
    getHidden: (_, vizSettings) => vizSettings["pivot_table.body_transparent"],
  },
  "pivot_table.cell_even_background_color": {
    section: t`Colors`,
    title: t`Even rows background color`,
    widget: "color",
    getDefault: () => color("white"),
    getHidden: (_, vizSettings) => vizSettings["pivot_table.body_transparent"],
  },
  "pivot_table.left_header_background_color": {
    section: t`Colors`,
    title: t`Left header background color`,
    widget: "color",
    getDefault: () => color("white"),
    getHidden: (_, vizSettings) => vizSettings["pivot_table.body_transparent"],
  },
  "pivot_table.left_header_text_color": {
    section: t`Colors`,
    title: t`Left header text color`,
    widget: "color",
    getDefault: () => color("text-dark"),
  },
  "pivot_table.cell_background_color_hover": {
    section: t`Colors`,
    title: t`Cell hover color`,
    widget: "color",
    getDefault: () => color("bg-medium"),
  },
  "pivot_table.toggle_clicked_row_higlight": {
    section: t`Colors`,
    title: t`Toggle clicked row higlight`,
    widget: "toggle",
    default: true,
  },

  // pivot table header settings
  "pivot_table.title_font_size": {
    section: t`Cells`,
    title: t`Title font size`,
    widget: "number",
    default: 12,
  },
  "pivot_table.title_font_italic": {
    section: t`Cells`,
    title: t`Title font italic`,
    widget: "toggle",
    getDefault: (series, vizSettings) =>
      !!vizSettings["pivot_table.title_font_italic"],
  },
  "pivot_table.title_font_bold": {
    section: t`Cells`,
    title: t`Title font bold`,
    widget: "toggle",
    getDefault: (series, vizSettings) =>
      !!vizSettings["pivot_table.title_font_bold"],
  },
  "pivot_table.title_auto_height": {
    section: t`Cells`,
    title: t`Title auto height`,
    widget: "toggle",
    default: true,
  },
  "pivot_table.title_height": {
    section: t`Cells`,
    title: t`Title height`,
    widget: "number",
    getProps: (series, vizSettings) =>
      vizSettings["pivot_table.title_height"] <
      vizSettings["pivot_table.title_font_size"]
        ? vizSettings["pivot_table.title_font_size"]
        : vizSettings["pivot_table.title_height"],
    getDefault: (series, vizSettings) =>
      vizSettings["pivot_table.title_auto_height"]
        ? vizSettings["pivot_table.title_font_size"] + DEFAULT_CELL_PADDING * 2
        : vizSettings["pivot_table.title_font_size"],
    isValid: (series, vizSettings) =>
      vizSettings["pivot_table.title_height"] >
        vizSettings["pivot_table.title_font_size"] &&
      vizSettings["pivot_table.title_height"] <= 1000,
    readDependencies: [
      "pivot_table.title_font_size",
      "pivot_table.title_auto_height",
    ],
    getHidden: (series, vizSettings) =>
      vizSettings["pivot_table.title_auto_height"],
  },
  "pivot_table.header_horizontal_alignment": {
    section: t`Cells`,
    title: t`Title text horizontal alignment`,
    widget: "select",
    props: {
      options: [
        { name: t`Left`, value: "flex-start" },
        { name: t`Center`, value: "center" },
        { name: t`Right`, value: "flex-end" },
      ],
    },
    default: "center",
  },
  "pivot_table.title_vertical_alignment": {
    section: t`Cells`,
    title: t`Title text vertical alignment`,
    widget: "select",
    props: {
      options: [
        { name: t`Top`, value: "flex-start" },
        { name: t`Center`, value: "center" },
        { name: t`Bottom`, value: "flex-end" },
      ],
    },
    default: "center",
  },
  "pivot_table.title_auto_indent": {
    section: t`Cells`,
    title: t`Title auto indent`,
    widget: "toggle",
    default: true,
  },
  "pivot_table.title_indent_left": {
    section: t`Cells`,
    title: t`Title horizontal indent`,
    widget: "number",
    default: DEFAULT_CELL_PADDING,
    getProps: (series, vizSettings) =>
      vizSettings["pivot_table.title_indent_left"] < 0
        ? 0
        : vizSettings["pivot_table.title_indent_left"],
    isValid: (series, vizSettings) =>
      vizSettings["pivot_table.title_indent_left"] >= 0,
    readDependencies: ["pivot_table.title_auto_indent"],
    getHidden: (series, vizSettings) =>
      vizSettings["pivot_table.title_auto_indent"],
  },
  "pivot_table.titile_indent_vertical": {
    section: t`Cells`,
    title: t`Title vertical indent`,
    widget: "number",
    default: DEFAULT_CELL_PADDING,
    getProps: (series, vizSettings) => {
      if (vizSettings["pivot_table.titile_indent_vertical"] < 0) {
        return 0;
      }
      if (!vizSettings["pivot_table.title_auto_height"]) {
        const newHeight =
          vizSettings["pivot_table.titile_indent_vertical"] +
          vizSettings["pivot_table.title_font_size"];
        if (vizSettings["pivot_table.title_height"] < newHeight) {
          return (
            vizSettings["pivot_table.title_height"] -
            vizSettings["pivot_table.title_font_size"]
          );
        }
      }
      return vizSettings["pivot_table.titile_indent_vertical"];
    },
    isValid: (series, vizSettings) => {
      if (vizSettings["pivot_table.titile_indent_vertical"] < 0) {
        return false;
      }
      if (!vizSettings["pivot_table.title_auto_height"]) {
        return (
          vizSettings["pivot_table.title_height"] >=
          vizSettings["pivot_table.titile_indent_vertical"] +
            vizSettings["pivot_table.title_font_size"]
        );
      }
      return true;
    },
    readDependencies: [
      "pivot_table.title_auto_indent",
      "pivot_table.title_height",
      "pivot_table.title_auto_height",
    ],
    getHidden: (series, vizSettings) =>
      vizSettings["pivot_table.title_auto_indent"],
  },

  "pivot_table.row_clicked_color": {
    section: t`Colors`,
    title: t`Row clicked color`,
    widget: "color",
    getDefault: () => color("shadow"),
    getHidden: (_, vizSettings) =>
      !vizSettings["pivot_table.toggle_clicked_row_higlight"],
  },

  "pivot_table.header_text_color": {
    section: t`Colors`,
    title: t`Header text color`,
    widget: "color",
    getDefault: () => color("text-dark"),
  },
  "pivot_table.header_background_color": {
    section: t`Colors`,
    title: t`Header background color`,
    widget: "color",
    getDefault: () => color("white"),
    getHidden: (_, vizSettings) =>
      vizSettings["pivot_table.header_transparent"],
  },
  "pivot_table.header_background_color_hover": {
    section: t`Colors`,
    title: t`Header hover color`,
    widget: "color",
    getDefault: () => color("bg-medium"),
  },
  "pivot_table.header_transparent": {
    section: t`Colors`,
    title: t`Transparent table header background`,
    widget: "toggle",
    default: true,
  },
  "pivot_table.body_transparent": {
    section: t`Colors`,
    title: t`Transparent table body background`,
    widget: "toggle",
    default: true,
  },

  "pivot_table.pin_mode": {
    section: t`Cells`,
    title: t`Cell pin mode`,
    widget: "toggle",
    default: false,
  },
  "pivot_table.pinned_columns_count": {
    section: t`Cells`,
    title: t`Pinned columns count`,
    widget: "number",
    default: 1,
    isValid: (_, vizSettings) =>
      vizSettings["pivot_table.pinned_columns_count"] < 1
        ? 1
        : vizSettings["pivot_table.pinned_columns_count"],
    getHidden: (_, vizSettings) => !vizSettings["pivot_table.pin_mode"],
  },
  "pivot_table.pinned_rows_count": {
    section: t`Cells`,
    title: t`Pinned rows count`,
    widget: "number",
    default: 1,
    isValid: (_, vizSettings) =>
      vizSettings["pivot_table.pinned_rows_count"] < 1
        ? 1
        : vizSettings["pivot_table.pinned_rows_count"],
    getHidden: (_, vizSettings) => !vizSettings["pivot_table.pin_mode"],
  },

  "pivot_table.hierarchy": {
    section: t`Columns`,
    title: t`Show hierarchy`,
    widget: "toggle",
    default: false,
    inline: true,
  },
  "pivot.show_row_totals": {
    section: t`Columns`,
    title: t`Show row totals`,
    widget: "toggle",
    default: true,
    inline: true,
  },
  "pivot.show_column_totals": {
    section: t`Columns`,
    title: t`Show column totals`,
    widget: "toggle",
    default: true,
    inline: true,
    isValid: (_, vizSettings) =>
      vizSettings["pivot_table.hierarchy"]
        ? vizSettings["pivot.show_column_totals"]
        : true,
    getHidden: (_, vizSettings) => vizSettings["pivot_table.hierarchy"],
  },
  "pivot_table.grid_color": {
    section: t`Colors`,
    title: t`Grid table color`,
    widget: "color",
    default: color("border"),
  },
  "pivot_table.method_of_request": {
    section: t`Display`,
    title: t`Parameters of the original request`,
    widget: "toggle",
    default: false,
  },
};
